import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { SelectPicker, Checkbox, CheckboxGroup, Button } from "rsuite";
//api
import { product, category, brand } from "../../../api/Api";

export default class gride extends Component {
  state = {
    category: [{ label: "", value: "" }],
    data: [],
    brand: [],
    brandId: [],
    show: false,
    showBrand: false,
  };

  componentDidMount() {
    this.getAllProduct();
    this.getAllCategory();
    this.getAllBrand();
  }

  getAllProduct = async (brandId, categoryId) => {
    const { data } = await product.getAll(brandId);
    this.setState({ data: data.data });
  };

  getAllCategory = async () => {
    const { data } = await category.getAll();

    let newValue = [];

    data.data.forEach((e) => {
      newValue = [...newValue, { label: e.name, value: e.id }];
    });
    this.setState({ category: newValue });
  };

  getAllBrand = async () => {
    const { data } = await brand.getAll();

    this.setState({ brand: data.data });
  };

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  handleToggleBrand = () => {
    this.setState({
      showBrand: !this.state.showBrand,
    });
  };

  handleChange = async (value) => {
    await this.setState({
      brandId: value,
    });
  };

  handleChangeCategory = async (value) => {
    await this.setState({
      values: { ...this.state.values, categoryId: value },
      categoryId: value,
    });
    this.getAllProduct(this.state.values);
    // this.setState({
    //   value,
    // });
  };

  submitBrand = async () => {
    await this.setState({
      values: { ...this.state.values, brandIds: this.state.brandId },
    });
    this.getAllProduct(this.state.values);
  };
  render() {
    const { data, showBrand, show, category, brand, brandId } = this.state;
    return (
      <main>
        <div className="m1 flex-j-c w-100">
          <div className="m1-b flex-j-s w1-b">
            <div className="m1-bl d-r ">
              <div className="m1-bll">
                <ul className="m1-bll-u flex">
                  <li id="m1-bll-u">مرتب سازی براساس:</li>
                  <li>کمترین قیمت</li>
                  <li>زودترین زمان</li>
                  <li>جدیدترین</li>
                  <li>پربازدیدترین</li>
                  <li>محبوب ترین</li>
                </ul>
              </div>

              <div className="m1-blc-pet flex-j-c ">
                <div className=" flex-j-c ">
                  <div className="m1-blc1-pet flex wrap">
                    {data &&
                      data.length &&
                      data.map((x) => {
                        return (
                          <NavLink to={`/product/flyer`}>
                            <div className="m1-blc1-1-pet flex-j-s">
                              <div className="m1-blc1-1i-pet flex-j-c ">
                                <img
                                  alt=""
                                  src={x.images.length && x.images[0].url}
                                />
                              </div>
                              <div
                                className="m1-blc1-1n-pet"
                                id="m1-blc1-1n-pet"
                              >
                                {x.name}
                              </div>
                              <div className="m1-blc1-1a-pet">
                                <span className="m1-blc1-1as">
                                  {x.productPricings &&
                                    x.productPricings[0] &&
                                    x.productPricings[0].price}
                                </span>
                                <span>
                                  {x.productPricings &&
                                    x.productPricings[0] &&
                                    x.productPricings[0].currency.name}
                                </span>
                              </div>
                              <div className=" flex-j-c ">
                                <ul className="m1-blc1-1b-pet flex-j-c c-p">
                                  <li className="m1-blc1-1b3"> + </li>
                                  <li className="m1-blc1-1b2"> 1 </li>
                                  <li className="m1-blc1-1b1"> - </li>
                                </ul>
                              </div>
                            </div>
                          </NavLink>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="m1-blr flex-j-c d-l">
                <div className="m1-blr1 mdi mdi-chevron-left c-p"> قبلی</div>
                <ul className="m1-blru flex c-p">
                  <li>1</li>
                  <li>2</li>
                  <li>3</li>
                  <li>4</li>
                  <li>5</li>
                  <li>6</li>
                </ul>
                <div className="m1-blr1 mdi mdi-chevron-right  d-r c-p">
                  بعدی
                </div>
              </div>
            </div>

            <div className="m1-br flex">
              <div className="m1-br1">
                <div className="m1-brt">
                  <div
                    onClick={this.handleToggle}
                    className="m1-brt1 mdi mdi-chevron-up flex-j-s c-p cursor-p"
                  >
                    دسته بندی نتایج
                  </div>
                  {show ? (
                    <div className="flex-j-c">
                      <div className="w-80">
                        <SelectPicker
                          onChange={this.handleChangeCategory}
                          data={category}
                          block
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="m1-brb">
                  <div className="m1-brt1 mdi mdi-chevron-up flex-j-s c-p">
                    براساس نوع
                  </div>
                  <div className="m1-brb1">
                    <label>همه</label> <input type="checkbox" /> <br />
                    <label>قلاده</label> <input type="checkbox" /> <br />
                    <label>پوشاک</label> <input type="checkbox" /> <br />
                    <label>شانه و برس</label> <input type="checkbox" /> <br />
                    <label>ظرف آب و غذا</label> <input type="checkbox" />
                  </div>
                  <div>
                    <p className="m1-brb2 c-p">+مشاهده بیشتر </p>
                  </div>
                </div> */}
                <div className="m1-brb">
                  <div
                    onClick={this.handleToggleBrand}
                    className="m1-brt1 mdi mdi-chevron-up flex-j-s c-p  cursor-p"
                  >
                    براساس برند
                  </div>
                  {showBrand ? (
                    <div className="m1-brb1 ">
                      <CheckboxGroup
                        name="checkboxList"
                        value={this.state.value}
                        onChange={this.handleChange}
                      >
                        {brand &&
                          brand.length &&
                          brand.map((x) => {
                            return <Checkbox value={x.id}>{x.name}</Checkbox>;
                          })}
                      </CheckboxGroup>
                      <div className="flex-j-c">
                        <Button
                          onClick={this.submitBrand}
                          disabled={!brandId.length}
                        >
                          جستوجو
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
