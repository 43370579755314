import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
  Button,
} from "rsuite";
import { account } from "../../../../api/Api";
// API

// Components

export default class LoginForm extends Component {
  state = {
    formValue: {
      lastName: this.props.user && this.props.user.lastName,
      firstName: this.props.user && this.props.user.firstName,
    },
  };

  componentDidMount() {}

  handleChange = (name, e) => {
    this.setState({ formValue: { ...this.state.formValue, [name]: e } });
  };

  handleSubmit = async () => {
    try {
      await account.update(this.state.formValue);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return (
      <Fragment>
        <main>
          <div class="m6 flex-j-c w-100">
            <div class="m6-b flex-j-s w1-b">
              <div class="m6-bl d-r flex">
                <div class="m9-blt">
                  {" "}
                  <p>اطلاعات کاربری</p>
                </div>
                <div class="m6-blb flex">
                  <Form formDefaultValue={this.state.formValue} fluid>
                    <FormGroup>
                      <ControlLabel>شماره همراه</ControlLabel>
                      <FormControl
                        value={this.props.user && this.props.user.phoneNumber}
                        disabled
                        name="phoneNumber"
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>نام</ControlLabel>
                      <FormControl
                        onChange={(e) => this.handleChange("firstName", e)}
                        name="firstName"
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>نام خوانوادگی</ControlLabel>
                      <FormControl
                        onChange={(e) => this.handleChange("lastName", e)}
                        name="lastName"
                      />
                    </FormGroup>

                    <FormGroup>
                      <ButtonToolbar>
                        <Button
                          onClick={this.handleSubmit}
                          color="orange"
                          appearance="primary"
                        >
                          تایید
                        </Button>
                      </ButtonToolbar>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
