import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "rsuite";
import Modal from "../component/modal";
import notif from "../../../utils/notif";
//api
import { mentor } from "../../../api/Api";

export default class gride extends Component {
  state = {
    data: [],
    show: false,
  };

  close = () => {
    this.setState({
      show: false,
    });
  };
  open = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      notif("برای ثبت نام لطفا ابتدا لاگین کنید");
    } else {
      this.setState({
        show: true,
      });
    }
  };

  componentDidMount() {
    this.getAllMentor();
  }

  getAllMentor = async () => {
    try {
      const data = await mentor.getAll();
      if (data.status !== 204) {
        this.setState({ data: data.data.data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  handleChange = async (value) => {
    await this.setState({
      brandId: value,
    });
  };
  handleModal = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      notif("برای ثبت نام لطفا ابتدا لاگین کنید");
    } else {
      this.setState({
        show: true,
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <main>
        <Modal show={this.state.show} open={this.open} close={this.close} />
        <div class="coachlist-main1 w-100 flex-j-c">
          <div style={{ direction: "rtl" }} class="coachlist-main1-baba ">
            <Button
              onClick={() => {
                this.handleModal();
              }}
              style={{ marginBottom: "10px" }}
              color="orange"
            >
              عضویت مربیان
            </Button>
            <div class=" flex wrap">
              {data.map((x) => {
                return (
                  <div class="coachlist-child1">
                    <div class="flex-j-c">
                      <img
                        alt=""
                        src={x.images && x.images[0] && x.images[0].url}
                      />
                    </div>
                    <div class="coachlist-child1-txt1">
                      {" "}
                      {x.firstName + " " + x.lastName}
                    </div>
                    <div class="coachlist-child1-txt2">{x.phoneNumber}</div>
                    <NavLink to={`/mentor/${x.id}`}>
                      <div class="coachlist-child1-txt4">مشاهده</div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>
    );
  }
}
