import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { setAuthToken } from "./api/_api";
import Login from "./containers/auth";
import Home from "./containers/home";
import Product from "./containers/product";

import Mentor from "./containers/mentor";
import MentorDetails from "./containers/mentorDetails";

import Cart from "./containers/cart";
import Profile from "./containers/profile";

import Payment from "./containers/payment";

//static pages
import Faq from "./containers/faq";
import Roles from "./containers/roles";
import AboutUs from "./containers/aboutUs";
import Anfrage from "./containers/anfrage";
import ContactUs from "./containers/contactUs";

import FlyerProductDetails from "./containers/flyer/productDetails";
import BuyFlyer from "./containers/flyer/buyFlyer";

import FolderProductDetails from "./containers/folder/productDetails";
import BuyFolder from "./containers/folder/buy";

import FolderWickelfalz6seitenProductDetails from "./containers/FolderWickelfalz6seiten/productDetails";
import BuyFolderWickelfalz6seiten from "./containers/FolderWickelfalz6seiten/buy";

import FolderWickelfalz8seitenProductDetails from "./containers/FolderWickelfalz8seiten/productDetails";
import BuyFolderWickelfalz8seiten from "./containers/FolderWickelfalz8seiten/buy";

import FolderZfalz6SeitenProductDetails from "./containers/FolderZfalz6Seiten/productDetails";
import BuyFolderZfalz6Seiten from "./containers/FolderZfalz6Seiten/buy";

import FolderZfalz8SeitenProductDetails from "./containers/FolderZfalz8Seiten/productDetails";
import BuyFolderZfalz8Seiten from "./containers/FolderZfalz8Seiten/buy";

import RollUpProductDetails from "./containers/RollUp/productDetails";
import BuyRollUp from "./containers/RollUp/buy";

import LesezeichenProductDetails from "./containers/Lesezeichen/productDetails";
import BuyLesezeichen from "./containers/Lesezeichen/buy";

import ButtonProductDetails from "./containers/Button/productDetails";
import BuyButton from "./containers/Button/buy";
export default class App extends Component {
  state = {
    routes: [
      {
        route: `/`,
        exact: true,
        component: Home,
      },
      {
        route: `/login`,
        exact: true,
        component: Login,
      },

      {
        route: `/product`,
        exact: true,
        component: Product,
      },

      {
        route: `/mentor`,
        exact: true,
        component: Mentor,
      },
      {
        route: `/mentor/:id`,
        exact: true,
        component: MentorDetails,
      },
      {
        route: `/cart`,
        exact: true,
        component: Cart,
      },
      {
        route: `/profile`,
        exact: true,
        component: Profile,
      },
      //static pages
      {
        route: `/faq`,
        exact: true,
        component: Faq,
      },
      {
        route: `/roles`,
        exact: true,
        component: Roles,
      },
      {
        route: `/aboutUs`,
        exact: true,
        component: AboutUs,
      },
      {
        route: `/anfrage`,
        exact: true,
        component: Anfrage,
      },
      {
        route: `/contactUs`,
        exact: true,
        component: ContactUs,
      },
      {
        route: `/product/flyer`,
        exact: true,
        component: FlyerProductDetails,
      },
      {
        route: `/buyFlyer`,
        exact: true,
        component: BuyFlyer,
      },

      {
        route: `/product/folder`,
        exact: true,
        component: FolderProductDetails,
      },
      {
        route: `/buyFolder`,
        exact: true,
        component: BuyFolder,
      },

      {
        route: `/product/FolderZfalz8Seiten`,
        exact: true,
        component: FolderZfalz8SeitenProductDetails,
      },
      {
        route: `/buyFolderZfalz8Seiten`,
        exact: true,
        component: BuyFolderZfalz8Seiten,
      },

      {
        route: `/product/FolderWickelfalz6seiten`,
        exact: true,
        component: FolderWickelfalz6seitenProductDetails,
      },
      {
        route: `/buyFolderWickelfalz6seiten`,
        exact: true,
        component: BuyFolderWickelfalz6seiten,
      },

      {
        route: `/product/FolderWickelfalz8seiten`,
        exact: true,
        component: FolderWickelfalz8seitenProductDetails,
      },
      {
        route: `/buyFolderWickelfalz8seiten`,
        exact: true,
        component: BuyFolderWickelfalz8seiten,
      },

      {
        route: `/product/FolderZfalz6Seiten`,
        exact: true,
        component: FolderZfalz6SeitenProductDetails,
      },
      {
        route: `/buyFolderZfalz6Seiten`,
        exact: true,
        component: BuyFolderZfalz6Seiten,
      },

      {
        route: `/product/RollUp`,
        exact: true,
        component: RollUpProductDetails,
      },
      {
        route: `/buyRollUp`,
        exact: true,
        component: BuyRollUp,
      },

      {
        route: `/product/Lesezeichen`,
        exact: true,
        component: LesezeichenProductDetails,
      },
      {
        route: `/buyLesezeichen`,
        exact: true,
        component: BuyLesezeichen,
      },
      {
        route: `/product/Button`,
        exact: true,
        component: ButtonProductDetails,
      },
      {
        route: `/buyButton`,
        exact: true,
        component: BuyButton,
      },
      {
        route: `/payment/:id`,
        exact: true,
        component: Payment,
      },
    ],
  };

  componentDidMount() {
    //  avale sobh

    //1 sari mirim to master pull migirim barmigardim to aye control + shift + p (merge branch)  ba master , bilbilak
    //shoru mikonim b kar

    // ahare shab

    //1 commit mokonim kararo
    //2 bilbilak
    //3 sari mirim to master va pull migirim va sari barmigardim to ayeh va   control + shift + p  (merge branch) ba master va badesh bilbilak
    //4 sari mirim to master control + shift + p (merge branch) ba aye
    //5 sari barmigardim to ayeh

    //6 khamosh mikonim in lanatio , va mevak mizanim , mikhabim

    const token = localStorage.getItem("token");
    setAuthToken(token);
  }

  render() {
    // const token = localStorage.getItem("token");
    // if (!token) {
    //   return (
    //     <Router>
    //       <Switch>
    //         <Route exact={true} path={"/login"} component={Login} />
    //         <Redirect to="/login" />
    //       </Switch>
    //     </Router>
    //   );
    // }
    return (
      <Router>
        <Switch>
          {this.state.routes.map((route, k) => (
            <Route
              key={k}
              exact={route.exact}
              path={route.route}
              component={route.component}
            />
          ))}
        </Switch>
      </Router>
    );
  }
}
