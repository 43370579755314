import { APIClient } from "./_api";

const endpoint = "/Image";

export function addImages(data) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append("Images", data[key]);
  }
  return APIClient.post(`${endpoint}`, bodyFormData);
}
