import React, { Component } from "react";

import img1 from "../../../../assets/img/rullup.jpg";
import img2 from "../../../../assets/img/falz.png";
import img3 from "../../../../assets/img/blocke.png";
import img4 from "../../../../assets/img/Broschüren.png";
import img5 from "../../../../assets/img/Buchdruck.png";
import img6 from "../../../../assets/img/Bürodruck Sorten.png";

import { NavLink } from "react-router-dom";
class ProductDetails extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello

    this.getAllProduct();
  }

  getAllProduct = async () => {};

  render() {
    // const token = localStorage.getItem("token");
    return (
      <main>
        <div class="main1-product-detail w-100 flex-j-c">
          <div class="main1-product-detail-dad w-90 flex-j-s">
            <div class="main1-product-detail-child1">
              <i class="mdi mdi-tune"></i>
              <strong>Filter</strong>
            </div>
            <div class="main1-product-detail-child-left w-40">
              <div class="main1-product-detail-child-left-img1">
                <div class="position-r bc w-100">
                  <i class="mdi mdi-chevron-left position-a chevron-l-icn"></i>
                  <i class="mdi mdi-chevron-right position-a chevron-r-icn"></i>
                </div>
                <img src={img1} alt="" />
              </div>
              <div class="flex m-t-20">
                <div class="main1-product-detail-child-left-img2">
                  <img src={img1} alt="" />
                </div>
                <div class="main1-product-detail-child-left-img2-2">
                  <img src={img2} alt="" />
                </div>
              </div>
            </div>
            <div class="main1-product-detail-child-right w-80">
              <div class="main1-product-detail-child1-right-txt1">
                <a href="https://teleprint.at">Startseite</a>
                <span class="divider">/</span>
                <a href="https://teleprint.at/kategorie/Folder/">RollUp</a>
              </div>
              <div class="main1-product-detail-child1-right-txt2">RollUp</div>
              <div class="main1-product-detail-icn-res flex">
                <a
                  href="https://teleprint.at/produkte/Folder-einfachfalz/"
                  rel="next"
                  class="button icon is-outline circle"
                >
                  <i class="mdi mdi-less-than"></i>
                  <i class="mdi mdi-greater-than"></i>
                </a>
              </div>
              {/* <div class="main1-product-detail-child1-right-txt3">
                Der Preis wird nach Anfrage angegeben und Sie werden
                benachrichtigt
              </div> */}
              <div class="main1-product-detail-child1-right-txt4">
                Zusätzliche Hinweise: *
              </div>

              <div class="main1-product-detail-btn1">
                <NavLink to="/buyRollUp">
                  <button>kaufen</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div class="main2-product-detail flex-j-c w-100">
          <div class="main2-product-detail-dad w-90">
            <div class="main2-product-detail-child1">
              <span>Beschreibung </span>
            </div>
            <div class="main2-product-detail-child2">
              Ob ins Kino gehen, das Open-Air-Konzert genießen oder eine
              Messe-Veranstaltung besuchen. Bei so gut wie allen öffentlichen
              In-Door und Out-Door Veranstaltungen finden sich Roll-Ups, welche
              mit eindrucksvollen Farben und Designs im Hochkant-Format Lust auf
              Mehr machen wollen. Für Unternehmen und Freizeit-Events sind
              Roll-ups nicht wegzudenken, damit man bei möglichst vielen
              Menschen Interesse weckt. Vorteile von Roll-Ups sind zahlreich.
              Die Flexibilität Sie im freien, an der Wand oder auf der Wecke
              mehrmals anbringen zu können, so wie der leichte Aufbau und Abbau.
              Bei Teleprint können Sie zwischen verschiedenen Arten von
              Roll-up-Systemen aussuchen und das Material je nach Einsatzzweck
              selbst bestimmen.
            </div>
          </div>
        </div>
        <div class="main3-product-detail w-100 flex-j-c">
          <div class="main3-product-detail-dad w-90">
            <div class="main3-product-detail-txt">ÄHNLICHE PRODUKTE</div>
            <div class="flex-j-s">
              <div class="main3-product-detail-box">
                <img src={img3} alt="" />
                <div class="main2-home-txt1-pro">Blöcke</div>
                <div class="main2-home-txt2-pro">
                  {/* <a href="#">3 Produkte</a> */}
                </div>
              </div>
              <div class="main3-product-detail-box">
                <img src={img4} alt="" />
                <div class="main2-home-txt1-pro">
                  Broschüren/ Zeitungen/ Magazine
                </div>
                <div class="main2-home-txt2-pro">
                  {/* <a href="#">2 Produkte</a> */}
                </div>
              </div>
              <div class="main3-product-detail-box">
                <img src={img5} alt="" />
                <div class="main2-home-txt1-pro">Buchdruck</div>
                <div class="main2-home-txt2-pro">
                  {/* <a href="3">4 Produkte</a> */}
                </div>
              </div>
              <div class="main3-product-detail-box">
                <img src={img6} alt="" />
                <div class="main2-home-txt1-pro">Bürodruck Sorten</div>
                <div class="main2-home-txt2-pro">
                  {/* <a href="#"> 19 Produkte</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ProductDetails;
