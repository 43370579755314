import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { updateCart } from "../../../action";
import { on } from "jetemit";

import Logo from "../../../assets/img/TelePrint-Logo.svg";
//api
import { order } from "../../../api/Api";
import { Drawer } from "rsuite";
class Header extends Component {
  state = {
    data: [],
    reduxCart: "",
    openDrawerState: false,
    statusName: "not_submitted",
  };

  componentDidMount() {
    this.getOrder();
    on("order", (order) => {
      this.getOrder();
    });
  }

  openDrawer = async () => {
    await this.setState({ openDrawerState: true });
  };
  closeDrawer = () => {
    this.setState({ openDrawerState: false });
  };

  getOrder = async () => {
    try {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const email = localStorage.getItem("email");
      const newValue = {
        phoneNumber,
        email,
        statusName: this.state.statusName,
      };
      if ((phoneNumber, email)) {
        const { data, status } = await order.getUnsubmittedOrder(newValue);

        if (status === 200) {
          this.setState({
            order: data.data,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  handlePay = async () => {
    try {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const email = localStorage.getItem("email");

      const newValue = { phoneNumber, email };

      const data = await order.payment(newValue);

      window.location.assign(`/payment/${data.data.data.client_secret}`);
      // window.location.assign(
      //   `/payment/pi_3KBkSdHUz35n7Yaq1Q6IbpmC_secret_NxJLxPOwpUhgmHIbYEOzE6bEl`
      // );
    } catch (error) {
      console.error(error);
    }
  };

  cancelOrder = async (id) => {
    try {
      const { status } = await order.deleteOrder(id);
      if (status === 200) {
        this.getOrder();
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { openDrawerState, order } = this.state;
    return (
      <header>
        <Drawer
          backdrop="static"
          show={openDrawerState}
          onHide={this.closeDrawer}
        >
          <Drawer.Header>
            <Drawer.Title>Cart</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {order && order.length
                ? order.map((x) => {
                    return (
                      // <div style={{ width: "50%" }}>
                      //   <img style={{ width: "80%" }} src={img1} />

                      // </div>
                      <div>
                        <div class="card">
                          {/* <img
                            src="/w3images/jeans3.jpg"
                            alt="Denim Jeans"
                            style={{ width: "100%" }}
                          /> */}
                          <h1>{x.productName}</h1>
                          <div className="">
                            <a
                              className="aaa"
                              rel="noreferrer"
                              target="_blank"
                              href={x.tableId && x.tableId.file1}
                            >
                              file1
                            </a>
                            <br />
                            <a
                              className="aaa"
                              rel="noreferrer"
                              target="_blank"
                              href={x.tableId && x.tableId.file2}
                            >
                              file2
                            </a>
                          </div>

                          {/* <p class="price">$19.99</p> */}

                          <p>
                            <button onClick={() => this.cancelOrder(x.id)}>
                              Cancel
                            </button>
                          </p>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="card">
              <button style={{ marginTop: "10px" }} onClick={this.handlePay}>
                pay
              </button>{" "}
            </div>
          </Drawer.Body>
        </Drawer>
        <div className="head-1 w-100 flex-j-c">
          <div className="head1-dad w-90 flex-j-s">
            <div className="head-1-child1-left flex-j-s">
              <div className="head-1-child1-left-txt1">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://maps.google.com/?q=Westbahnstraße 9, 1070 Wien"
                >
                  <i className="mdi mdi-map-marker head1-icn1"></i>
                  Westbahnstraße 9, 1070 Wien
                </a>
              </div>
              <div className="head-1-child1-left-txt2">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="mailto:print@teleprint.at"
                >
                  <i className="mdi mdi-email-outline head1-icn1"></i>
                  print@teleprint.at
                </a>
              </div>
              <div className="head-1-child1-left-txt3">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="tel:+43 1 524 32 56"
                >
                  <i className="mdi mdi-phone head1-icn1"></i> +43 1 524 32 56
                </a>
              </div>
            </div>
            <div className="head-1-child1-right-icns flex">
              <div className="head-1-child1-right-icn-1">
                <a href="https://www.facebook.com/teleprint.wien/">
                  <i className="mdi mdi-facebook"></i>
                </a>
              </div>
              <div className="head-1-child1-right-icn-1">
                <a href="https://www.instagram.com/teleprint.at/">
                  <i className="mdi mdi-instagram"></i>
                </a>
              </div>
              <div className="head-1-child1-right-icn-2">
                <a href="mailto:print@teleprint.at">
                  <i className="mdi mdi-email"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky">
          <div className="head-2 w-100 flex-j-c">
            <div className="head2-dad w-90 flex-j-s">
              <div className="head2-child1-left flex">
                <div className="head2-child1-left-logo">
                  <img src={Logo} alt="" />
                </div>
                <div className="head2-child2-left flex-j-s">
                  <input type="search" placeholder="Produkte suchen" />
                  <i className="mdi mdi-magnify"></i>
                </div>
                <i className="head2-icn-res mdi mdi-menu"></i>
              </div>
              <div className="head2-child1-right flex">
                <div className="head2-child1-right-txt">
                  <a href="https://teleprint.at/mein-konto/">
                    teleprintdemo
                    <i className="mdi mdi-account"></i>
                  </a>
                </div>
                <div
                  onClick={this.openDrawer}
                  className="head2-child1-right-txt"
                >
                  <i className="mdi mdi-cart"></i>
                </div>
                <div className="head2-child1-right-bord"></div>
              </div>
              <div className="head2-res m-t-20 t-r">
                <ul className="flex">
                  <li className="head2-res-brd1"> </li>
                  <li className="head2-icn1-res">
                    <i className="mdi mdi-account"></i>
                  </li>
                  <li className="head2-res-brd1"></li>
                  <li className="head2-icn2-res">
                    <i className="mdi mdi-briefcase-outline"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="head-3 w-100 flex-j-c">
            <div className="head3-dad w-90">
              <ul className="head3-child1 flex">
                <li id="head3-li1">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <a href="https://teleprint.at/"> Produkte</a>
                </li>
                <li>
                  <NavLink to="/faq">Häufige Fragen (FAQ)</NavLink>
                </li>
                <li>
                  <NavLink to="/anfrage">Anfrage</NavLink>
                </li>
                <li>
                  <NavLink to="/aboutUs">Über uns </NavLink>
                </li>
                <li>
                  <NavLink to="/contactUs"> Kontakt</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateCart: function (value) {
    return dispatch(updateCart(value));
  },
});

export default connect(mapStatetoProps, mapDispatchToProps)(Header);
