import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../components/CheckoutForm";
// import "../components/App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// To avoid exposing it, don't submit any personally identifiable information through requests with this API key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  "pk_test_51Hk7j0HUz35n7YaqmDbx8f4OeRH97UvJdgLhTTdc7hx50IkpOD2ZVfuLgbF0Zz6JcLWdU8WptntBTIeU96moQoDR00dtJjzSFh"
);

export default function App(props) {
  const client_secret = props.props.match.params.id;
  const [
    clientSecret,
    //  setClientSecret
  ] = useState(client_secret);

  useEffect(() => {
    // if (props && props.match && props.match.params && props.match.params.id) {
    //   setClientSecret(props.match.params.id);
    // }
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: client_secret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
