import React, { Component } from "react";
import { Fragment } from "react";
// api

// Components
import Login from "../components/login";
import Verify from "../components/verify";
import { Redirect } from "react-router-dom";
import { Notification } from "rsuite";
export default class index extends Component {
  state = {
    uiPosition: [],

    Active: "step1",
    loginData: { phoneNumber: "", verifyCode: "" },
    expireInSeconds: 180,
  };

  componentDidMount() {}

  goNext = (step) => {
    this.setState({ Active: step });
  };

  loginData = (loginData) => {
    this.setState({ loginData });
  };

  RedirectToSite = () => {
    this.setState({ redirect: true });
  };

  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  render() {
    let { Active } = this.state;
    if (this.state.redirect) {
      return <Redirect to={`/`} />;
    }
    return (
      <Fragment>
        {Active === "step1" ? (
          <Login
            goNext={this.goNext}
            loginData={this.loginData}
            open={this.open}
          />
        ) : Active === "step2" ? (
          <Verify
            goNext={this.goNext}
            data={this.state}
            redirect={this.RedirectToSite}
            expireInSeconds={this.state.expireInSeconds}
            open={this.open}
          />
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}
