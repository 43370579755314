import React, { Component } from "react";
import { Form, FormGroup, FormControl, ButtonToolbar, Button } from "rsuite";

// import './index.css';

// API
import { account } from "../../../../api/Api";

import { setAuthToken } from "../../../../api/_api";

// Components

// import * as Yup from "yup";

export default class LoginForm extends Component {
  state = {
    expireInSeconds: 180,
    id1: true,
    id2: false,
    id3: false,
    id4: false,

    plus: "",
    initialValues: {
      phoneNumber:
        this.props &&
        this.props.data &&
        this.props.data.loginData &&
        this.props.data.loginData.phoneNumber
          ? this.props.data.loginData.phoneNumber
          : localStorage.getItem("phoneNumber"),
    },
  };

  handleSubmit = async () => {
    console.log(this.props && this.props.data && this.props.data.loginData);

    try {
      let newValues = {
        phoneNumber: this.state.initialValues.phoneNumber,
        verifyCode: this.state.plus,
      };
      let data = await account.verify(newValues);
      if (data.data && data.data.data && data.data.data.token) {
        localStorage.setItem("token", data.data.data.token);
        localStorage.setItem("data", JSON.stringify(data.data));
        localStorage.setItem(
          "phoneNumber",
          this.state.initialValues.phoneNumber
        );
        setAuthToken(data.data.data.token);
        this.props.open("success", "تایید", "ورود با موفقیت انجام شد");
        this.props.redirect();
      }

      // this.props.loginData(this.state);
      // this.props.goNext();
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.time();

    // set Interval
    this.interval = setInterval(this.time, 1000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  time = async () => {
    await this.setState({
      expireInSeconds: Number(this.state.expireInSeconds - 1),
    });
  };

  // ValidationSchema = Yup.object().shape({
  //   phoneNumber: Yup.string()
  //     .required()
  //     .min(11)
  //     .max(11)
  //     .matches(
  //       /^(09)(10|11|12|13|14|15|16|17|18|19|90|91|32|30|33|35|36|37|38|39|01|02|03|04|05|41|20|21|22)\d{7}/,
  //       "لطفا شماره موبایل معتبر وارد نمایید"
  //     ),
  // });

  backPage = () => {
    this.props.goNext(1);
    localStorage.setItem("status", "");
    localStorage.setItem("phoneNumber", "");
  };

  // onChange =async (name, value) => {
  //  await this.setState({
  //     initialValue: { ...this.state.initialValue, [name]: value },
  //   });
  // };

  onChange = async (name, value) => {
    if (name === "4" && value === "") {
      document.getElementById("id3").focus();
    } else if (name === "3" && value === "") {
      document.getElementById("id2").focus();
    } else if (name === "2" && value === "") {
      document.getElementById("id1").focus();
    }
    if (name === "1" && value) {
      document.getElementById("id2").focus();
    } else if (name === "2" && value) {
      document.getElementById("id3").focus();
    } else if (name === "3" && value) {
      document.getElementById("id4").focus();
    }
    await this.setState({
      numbers: { ...this.state.numbers, [name]: value },
    });
    let plus =
      this.state.numbers[1] +
      this.state.numbers[2] +
      this.state.numbers[3] +
      this.state.numbers[4];

    this.setState({ plus });
  };

  sendCode = async () => {
    // console.log(this.initialValues);
    // await account.checkMobile(this.initialValues);
  };

  backPage = () => {
    this.props.goNext(1);
    localStorage.setItem("status", "");
    localStorage.setItem("phoneNumber", "");
  };

  render() {
    return (
      <div class="headmo flex-j-c w-100">
        <div class="headmo-b flex-j-c flexd">
          <div class="hmo1-2 flex-j-c">
            <div class="hmo2 flex-j-c">
              <div class="hmo3 flex-j-s">
                <div>
                  <div class="txt1m">پت شـاپ حـــامـــی </div>
                  <div class="txt2m">فروشگاه حیوانات خانگی </div>
                </div>
                <div class="moimg1">
                  <img alt="" src="index.img/Vector (2).png" />
                </div>
              </div>
            </div>
          </div>
          <div class="hmo4">
            <h1>ورود به برنامه</h1>
            <h4> .کد ارسال شده به شماره 09109064472 را وارد نمایید</h4>
            <div class="flex-j-c">
              <div class="hcode flex-j-s">
                <div class="cdleft p-r">
                  <i class="mdi mdi-timer-outline"></i>{" "}
                  <p>
                    <p className="font14 regular margleft-4 p blue">
                      {this.state.expireInSeconds}
                    </p>
                  </p>
                </div>
                <div class="cdright">
                  {this.state.expireInSeconds !== 0
                    ? "ارسال مجدد کد تایید"
                    : ""}{" "}
                  <i class="mdi mdi-reload"></i>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="inputcd flex-j-s">
            <div>
              {" "}
              <input />
            </div>
            <div>
              {" "}
              <input />
            </div>
            <div>
              {" "}
              <input />
            </div>
            <div>
              {" "}
              <input />
            </div>
          </div> */}

          {/* <div class="flexd">
            <button type="submit" class="buttco">
              {" "}
              ورود به برنامه{" "}
            </button>
          </div> */}

          <Form layout="horizontal" fluid>
            <FormGroup
              style={{ direction: "rtl" }}
              className="pozrel flex inputcd"
            >
              <FormControl
                id="id4"
                autoFocus={this.state.id4}
                maxlength="1"
                onChange={(e) => this.onChange("4", e)}
                className="verify gray font24 textcenter"
              />
              <FormControl
                id="id3"
                autoFocus={this.state.id3}
                maxlength="1"
                onChange={(e) => this.onChange("3", e)}
                className="verify gray font24 textcenter"
              />
              <FormControl
                id="id2"
                focus={this.state.id2}
                maxlength="1"
                onChange={(e) => this.onChange("2", e)}
                className="verify gray font24 textcenter "
              />
              <FormControl
                id="id1"
                autoFocus={this.state.id1}
                maxlength="1"
                onChange={(e) => this.onChange("1", e)}
                className="verify gray font24 textcenter"
              />

              {/* <HelpBlock>Required</HelpBlock> */}
            </FormGroup>
            <FormGroup>
              <ButtonToolbar>
                <Button onClick={this.handleSubmit} className="buttco">
                  وارد شو
                </Button>
              </ButtonToolbar>
            </FormGroup>
          </Form>
          <div class="txtco">تغییر شماره همراه</div>
        </div>
      </div>
    );
    // return (
    //   <div className="background">
    //     <FlexboxGrid>
    //       <FlexboxGrid.Item
    //         className="flex flexd"
    //         componentClass={Col}
    //         // lgHidden
    //         // mdHidden
    //         sm={24}
    //         xs={24}
    //       >
    //         <div className="boxsize flex flexd">
    //           <p className="regular font18">سوپر اپ پرداخت</p>
    //           <p className="font35 fat marg-0">
    //             جیب <span className="green fat">جیب</span>
    //           </p>
    //         </div>
    //         <div className="pozrel flex dad">
    //           <div className="divshadoww radius pozabso"></div>
    //           <div className="divshadow radius pozabso"></div>
    //           <div className="number flex flexd around radius pozabso">
    //             <div className="textcenter ">
    //               <p className="regular font18 gray margbot-20 ">
    //                 ورود به اپلیکیشن
    //               </p>

    //               <p className="regular font14 lightgray psize">
    //                 کد ارسال شده برای شماره را وارد نمایید.
    //                 <span className="font14 bold gray margrigh-4">
    //                   {phoneNumber && phoneNumber}
    //                 </span>
    //               </p>
    //             </div>
    //             <div className="timer radius">
    //               <Timer />
    //               <p className="font14 regular margleft-4 p blue">
    //                 {this.state.expireInSeconds}
    //               </p>
    //             </div>

    //             <Form layout="horizontal" fluid>
    //               <FormGroup className="pozrel flex">
    //                 <FormControl
    //                   id="id4"
    //                   autoFocus={this.state.id4}
    //                   maxlength="1"
    //                   onChange={(e) => this.onChange("4", e)}
    //                   className="verify gray font24 textcenter"
    //                 />
    //                 <FormControl
    //                   id="id3"
    //                   autoFocus={this.state.id3}
    //                   maxlength="1"
    //                   onChange={(e) => this.onChange("3", e)}
    //                   className="verify gray font24 textcenter"
    //                 />
    //                 <FormControl
    //                   id="id2"
    //                   focus={this.state.id2}
    //                   maxlength="1"
    //                   onChange={(e) => this.onChange("2", e)}
    //                   className="verify gray font24 textcenter "
    //                 />
    //                 <FormControl
    //                   id="id1"
    //                   autoFocus={this.state.id1}
    //                   maxlength="1"
    //                   onChange={(e) => this.onChange("1", e)}
    //                   className="verify gray font24 textcenter"
    //                 />

    //                 {/* <HelpBlock>Required</HelpBlock> */}
    //               </FormGroup>
    //               <FormGroup>
    //                 <ButtonToolbar>
    //                   <Button
    //                     onClick={this.handleSubmit}
    //                     className="button regular radius"
    //                   >
    //                     وارد شو
    //                   </Button>
    //                 </ButtonToolbar>
    //               </FormGroup>
    //             </Form>
    //             <div>
    //               <p
    //                 style={{ cursor: "pointer" }}
    //                 onClick={this.backPage}
    //                 className="green font14 regular"
    //               >
    //                 {" "}
    //                 تغییر شماره همراه
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //         <div>
    //           <p className=" font16 regular ">
    //             کد ارسال رو دریافت نکردی؟
    //             <span className="green regular font16 bold margrigh-4">
    //               ارسال مجدد
    //             </span>
    //           </p>
    //         </div>
    //       </FlexboxGrid.Item>
    //     </FlexboxGrid>
    //   </div>
    // );
  }
}
//  <div className="backgroundColor">
//         <FlexboxGrid>
//           <FlexboxGrid.Item
//             className="background form"
//             componentClass={Col}
//             lg={24}
//             md={24}
//             sm={24}
//             xs={24}
//           >
//             <Form className="display flexj flexd form2">
//               <FormGroup className="intro">
//                 <FormGroup className="info">
//                   <p className="color h5 padding"> تایید کد ورودی</p>

//                   <p className="colorGray p padding">
//                     کد 4 رقمی که برای شماره تلفن
//                     {this.props.data.phoneNumber
//                       ? this.props.data.phoneNumber
//                       : localStorage.getItem("phoneNumber")}{" "}
//                     ارسال شده وارد کنید.
//                   </p>
//                 </FormGroup>
//                 <FormGroup className="display width3  mtop">
//                   <FormControl
//                     id="id4"
//                     autoFocus={this.state.id4}
//                     maxlength="1"
//                     onChange={(e) => this.onChange("4", e)}
//                     className="input codeSize"
//                   />
//                   <FormControl
//                     id="id3"
//                     autoFocus={this.state.id3}
//                     maxlength="1"
//                     onChange={(e) => this.onChange("3", e)}
//                     className="input codeSize"
//                   />
//                   <FormControl
//                     id="id2"
//                     focus={this.state.id2}
//                     maxlength="1"
//                     onChange={(e) => this.onChange("2", e)}
//                     className="input codeSize "
//                   />
//                   <FormControl
//                     autoFocus={this.state.id1}
//                     maxlength="1"
//                     onChange={(e) => this.onChange("1", e)}
//                     className="input codeSize"
//                   />
//                 </FormGroup>
//                 <FormGroup className="display inputSize align ">
//                   <div>
//                     <p style={{ cursor: "pointer" }} onClick={this.sendCode}>
//                       ارسال دوباره کد در 02:20{" "}
//                     </p>
//                     <p>
//                       شماره اشتباه است ؟{" "}
//                       <a
//                         class="colorpink"
//                         onClick={this.backPage}
//                         style={{ cursor: "pointer" }}
//                         href
//                       >
//                         تغییر دهید
//                       </a>
//                     </p>
//                   </div>
//                 </FormGroup>
//               </FormGroup>
//               <FormGroup className="display fgap">
//                 <ButtonToolbar>
//                   <Button
//                     onClick={this.handleSubmit}
//                     className="button inputSize"
//                   >
//                     {" "}
//                     تکمیل ثبت نام
//                   </Button>
//                 </ButtonToolbar>
//               </FormGroup>
//             </Form>
//           </FlexboxGrid.Item>
//         </FlexboxGrid>
//       </div>
