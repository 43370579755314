import React, { Component, Fragment } from "react";
import img1 from "../../../../assets/img/rullup.jpg";
import img2 from "../../../../assets/img/falz.png";
import img3 from "../../../../assets/img/Kontakt(FAQ).png";
import img4 from "../../../../assets/img/Anfrage(FAQ).png";
import img5 from "../../../../assets/img/Buchdruck.png";
import img6 from "../../../../assets/img/Bürodruck Sorten.png";
import {
  Form,
  FormGroup,
  Notification,
  FormControl,
  SelectPicker,
  Button,
  ControlLabel,
  Schema,
  Loader,
} from "rsuite";

//api
import { RollUp } from "../../../../api/Api";

import { emit } from "jetemit";
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  // email: StringType()
  //   .isEmail("Please enter a valid email address.")
  //   .isRequired("This field is required."),
  count: NumberType("Please enter a valid number.").isRequired(
    "This field is required."
  ),
  width: NumberType().isRequired("End Format is required."),
  materialId: StringType().isRequired("material is required."),
});

const model1 = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  phoneNumber: StringType().isRequired("This field is required."),
});

export default class gride extends Component {
  state = {
    materialShow: false,
    src1: "",
    src2: "",
    steps: "step1",

    material: [],
    initialValues: {
      width: "",
      height: "",
      count: 1,
    },
    formError: {},
    loading: false,
    loading1: false,
    loadingRecord: false,
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  componentDidMount() {
    this.getEndFormat();
  }

  getEndFormat = async () => {
    try {
      const { data } = await RollUp.getAllEndFormat();
      let newData = [];
      data.data.forEach((element) => {
        newData = [
          ...newData,
          {
            label: element.key,
            value: element.width.toString() + "*" + element.height.toString(),
          },
        ];
      });

      this.setState({ endFormat: newData });
    } catch (error) {
      console.error(error);
    }
  };

  onChangeEndFormat = async (name, value) => {
    let strWidth = value && value.substring(0, value.indexOf("*"));
    let strHeight = value && value.split("*")[1];

    this.setState({
      materialShow: true,

      initialValues: {
        ...this.state.initialValues,
        width: strWidth,
        height: strHeight,
      },
    });
    this.getMaterial();
  };

  onChangeMaterialId = async (name, value) => {
    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        [name]: value,
      },
    });
  };

  onChange = async (name, value) => {
    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        [name]: value,
      },
    });
  };
  getMaterial = async () => {
    try {
      const { data } = await RollUp.digiPrintMaterial(this.state.initialValues);
      let newData = [];
      data.data.forEach((element) => {
        newData = [
          ...newData,
          {
            label: element.key + "       " + element.price + "€",
            value: element.id,
          },
        ];
      });

      this.setState({ material: newData });
    } catch (error) {
      console.error(error);
    }
  };

  handleStep = async (step) => {
    if (step === 2) {
      if (!this.form.check()) {
        if (this.state.formError.materialId) {
          this.open("error", "error", this.state.formError.materialId);
        }
        if (this.state.formError.width) {
          this.open("error", "error", this.state.formError.width);
        }
        if (this.state.formError.colorful) {
          this.open("error", "error", this.state.formError.colorful);
        }
        if (this.state.formError.doubleSided) {
          this.open("error", "error", this.state.formError.doubleSided);
        }

        console.error("Form Error");
        return;
      }
      this.getMaterial();
    }
    this.setState({ steps: `step${step}` });
  };

  handleChangefileOne = async ({ target }) => {
    this.setState({ loading: true });
    try {
      let file = target.files[0],
        src = target.value;
      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            file1: file,
          },
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);

      if (target.files && target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image1: e.target.result });
        };
        reader.readAsDataURL(target.files[0]);
      }

      this.setState({ file, src1: src });
    } catch (error) {}
    setTimeout(() => {
      this.setState({ loading: false });
    }, 5000);
  };
  handleChangefileTow = async ({ target }) => {
    this.setState({ loading1: true });
    try {
      let file = target.files[0],
        src = target.value;
      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            file2: file,
          },
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      if (target.files && target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image2: e.target.result });
        };
        reader.readAsDataURL(target.files[0]);
      }

      this.setState({ file, src2: src });
    } catch (error) {}
    setTimeout(() => {
      this.setState({ loading1: false });
    }, 5000);
  };

  handleSubmit = async () => {
    this.setState({ loadingRecord: true });
    try {
      if (!this.form1.check()) {
        if (this.state.formError1.email) {
          this.open("error", "error", this.state.formError.email);
        }
        if (this.state.formError1.phoneNumber) {
          this.open("error", "error", this.state.formError.phoneNumber);
        }

        console.error("Form Error");
        return;
      }
      if (!this.state.file) {
        return this.open("error", "error", "file 1 is requierd");
      }
      const { data } = await RollUp.record(this.state.initialValues);

      this.setState({ file1: "", file2: "" });

      emit("order", data);

      localStorage.setItem("phoneNumber", this.state.initialValues.phoneNumber);
      localStorage.setItem("email", this.state.initialValues.email);
      this.open(
        "success",
        "Bestätigung",
        "Ihre Bestellung wurde erfolgreich registriert "
      );
      this.handleStep(1);
    } catch (error) {
      this.open("error", "error", "there is a problem");
    }
    this.setState({ loadingRecord: false });
  };

  render() {
    const {
      endFormat,

      steps,
      material,
      materialShow,
      loadingRecord,
      loading,
      loading1,
      image2,
    } = this.state;
    return (
      <Fragment>
        <main>
          <div class="main1-product-detail w-100 flex-j-c">
            <div class="main1-product-detail-dad w-90 flex-j-s">
              <div class="main1-product-detail-child1">
                <i class="mdi mdi-tune"></i>
                <strong>Filter</strong>
              </div>
              <div class="main1-product-detail-child-left w-40">
                <div class="main1-product-detail-child-left-img1">
                  <div class="position-r bc w-100">
                    <i class="mdi mdi-chevron-left position-a chevron-l-icn"></i>
                    <i class="mdi mdi-chevron-right position-a chevron-r-icn"></i>
                  </div>
                  <img src={img1} alt="" />
                </div>
                <div class="flex m-t-20">
                  <div class="main1-product-detail-child-left-img2">
                    <img src={img1} alt="" />
                  </div>
                  <div class="main1-product-detail-child-left-img2-2">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
              <div class="main1-product-detail-child-right w-80">
                <div class="main1-product-detail-child1-right-txt1">
                  <a href="https://teleprint.at">Startseite</a>
                  <span class="divider">/</span>
                  <a href="https://teleprint.at/kategorie/Folder/">RollUp</a>
                </div>
                <div class="main1-product-detail-child1-right-txt2">RollUp</div>
                <div class="main1-product-detail-icn-res flex">
                  <a
                    href="https://teleprint.at/produkte/Folder-einfachfalz/"
                    rel="next"
                    class="button icon is-outline circle"
                  >
                    <i class="mdi mdi-less-than"></i>
                    <i class="mdi mdi-greater-than"></i>
                  </a>
                </div>
                {/* <div class="main1-product-detail-child1-right-txt3">
                  Der Preis wird nach Anfrage angegeben und Sie werden
                  benachrichtigt
                </div> */}

                <div class="main1-product-detail-child1-right-box1 flex-j-c">
                  <div class="w-95">
                    {steps === "step1" ? (
                      <Form
                        onCheck={(formError) => {
                          this.setState({ formError });
                        }}
                        ref={(ref) => (this.form = ref)}
                        formValue={this.state.initialValues}
                        model={model}
                        layout="horizontal"
                        fluid
                      >
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Anzahl:</ControlLabel>{" "}
                          <FormControl
                            className="rightInput"
                            placeholder="Anzahl"
                            name="count"
                            type="number"
                            required
                            onChange={(e) => this.onChange("count", e)}
                            style={{ width: "80%" }}
                          />
                        </FormGroup>
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>End Format:</ControlLabel>{" "}
                          <SelectPicker
                            style={{ width: "80%" }}
                            onChange={(e) =>
                              this.onChangeEndFormat("endFormatId", e)
                            }
                            name="endFormatId"
                            className=""
                            data={endFormat}
                            required
                            placeholder="End Format"
                            accepter={SelectPicker}
                          />
                        </FormGroup>

                        {materialShow ? (
                          <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                            <ControlLabel>Material:</ControlLabel>{" "}
                            <SelectPicker
                              style={{ width: "80%", marginBottom: "20px" }}
                              onChange={(e) => this.onChange("materialId", e)}
                              name="materialId"
                              className="rightInput"
                              data={material}
                              required
                              placeholder="Material"
                              accepter={SelectPicker}
                            />
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </Form>
                    ) : (
                      <Form
                        onCheck={(formError1) => {
                          this.setState({ formError1 });
                        }}
                        ref={(ref) => (this.form1 = ref)}
                        formValue={this.state.initialValues}
                        model={model1}
                        layout="horizontal"
                        fluid
                      >
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Phone Number:</ControlLabel>{" "}
                          <FormControl
                            className="rightInput"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            type="number"
                            required
                            onChange={(e) => this.onChange("phoneNumber", e)}
                          />
                        </FormGroup>

                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Email:</ControlLabel>{" "}
                          <FormControl
                            className="rightInput"
                            placeholder="Email"
                            name="email"
                            type="text"
                            required
                            onChange={(e) => this.onChange("email", e)}
                          />
                        </FormGroup>

                        <div class="main1-product-detail-child1-right-box1 flex-j-c">
                          <div class="w-95">
                            <div class="main1-product-detail-child1-right-box1-txt1">
                              Ihre Datei(en) hochladen
                            </div>
                            <div class="main1-product-detail-child1-right-box1-txt2">
                              Bitte laden Sie ausschließlich geschlossene
                              Dateiformate hoch: PDF, JPG, JPEG, PNG, TIFF, EPS
                            </div>

                            <div class="main1-product-detail-child1-right-box1-1">
                              <div
                                style={{ position: "relative", zIndex: "999" }}
                                class="main1-product-detail-child1-right-box1-1-icn t-c"
                              >
                                {loading ? (
                                  <Loader center content="loading" />
                                ) : (
                                  ""
                                )}

                                {this.state.image1 ? (
                                  <i
                                    style={{
                                      position: "absolute",
                                      top: "-11px",
                                      zIndex: "-5",
                                    }}
                                    class="mdi mdi-file-pdf"
                                  ></i>
                                ) : (
                                  <i
                                    style={{
                                      position: "absolute",
                                      top: "-11px",
                                      zIndex: "-5",
                                    }}
                                    class="mdi mdi-arrow-collapse-down"
                                  ></i>
                                )}

                                <div>
                                  <label className="custom-file-upload main1-lab-bargozari-ax">
                                    <div>
                                      <input
                                        name="image"
                                        type="file"
                                        multiple
                                        id="file"
                                        // accept="image/*"
                                        onChange={(e) =>
                                          this.handleChangefileOne(e)
                                        }
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="main1-product-detail-child1-right-box1-1">
                              <div
                                style={{ position: "relative", zIndex: "999" }}
                                class="main1-product-detail-child1-right-box1-1-icn t-c"
                              >
                                {loading1 ? (
                                  <Loader center content="loading" />
                                ) : (
                                  ""
                                )}
                                {image2 ? (
                                  // <img
                                  //   style={{
                                  //     width: "47px",
                                  //     height: "47px",
                                  //     position: "absolute",
                                  //   }}
                                  //   alt=""
                                  //   src={image2}
                                  // />
                                  <i
                                    style={{
                                      position: "absolute",
                                      top: "-11px",
                                      zIndex: "-5",
                                    }}
                                    class="mdi mdi-file-pdf"
                                  ></i>
                                ) : (
                                  <i
                                    style={{
                                      position: "absolute",
                                      top: "-11px",
                                      zIndex: "-5",
                                    }}
                                    class="mdi mdi-arrow-collapse-down"
                                  ></i>
                                )}
                                <div>
                                  <label className="custom-file-upload main1-lab-bargozari-ax">
                                    <div>
                                      <input
                                        name="image"
                                        type="file"
                                        multiple
                                        id="file"
                                        // accept="image/*"
                                        onChange={(e) =>
                                          this.handleChangefileTow(e)
                                        }
                                      />
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </div>{" "}
                  {loadingRecord ? (
                    <Loader backdrop size="lg" center content="loading" />
                  ) : (
                    ""
                  )}
                </div>

                <div class="main1-product-detail-btn1 flex-j-s">
                  <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                    <Button
                      appearance="primary"
                      onClick={() => this.handleStep(1)}
                      disabled={steps === "step1"}
                    >
                      Vorherige Stufe
                    </Button>
                  </FormGroup>
                  <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                    {steps === "step1" ? (
                      <Button
                        onClick={() => this.handleStep(2)}
                        appearance="primary"
                      >
                        nächste Stufe
                      </Button>
                    ) : (
                      <Button onClick={this.handleSubmit} appearance="primary">
                        einreichen
                      </Button>
                    )}
                  </FormGroup>
                </div>
                {/* <div class="main1-product-detail-btn1">
                  <NavLink to="/buyFolder">
                    <button>kaufen</button>
                  </NavLink>
                </div> */}
              </div>
            </div>
          </div>
          <div class="main2-product-detail flex-j-c w-100">
            <div class="main2-product-detail-dad w-90">
              <div class="main2-product-detail-child1">
                <span>Beschreibung </span>
              </div>
              <div class="main2-product-detail-child2">
                Ob ins Kino gehen, das Open-Air-Konzert genießen oder eine
                Messe-Veranstaltung besuchen. Bei so gut wie allen öffentlichen
                In-Door und Out-Door Veranstaltungen finden sich Roll-Ups,
                welche mit eindrucksvollen Farben und Designs im Hochkant-Format
                Lust auf Mehr machen wollen. Für Unternehmen und Freizeit-Events
                sind Roll-ups nicht wegzudenken, damit man bei möglichst vielen
                Menschen Interesse weckt. Vorteile von Roll-Ups sind zahlreich.
                Die Flexibilität Sie im freien, an der Wand oder auf der Wecke
                mehrmals anbringen zu können, so wie der leichte Aufbau und
                Abbau. Bei Teleprint können Sie zwischen verschiedenen Arten von
                Roll-up-Systemen aussuchen und das Material je nach Einsatzzweck
                selbst bestimmen.
              </div>
            </div>
          </div>
          <div class="main3-product-detail w-100 flex-j-c">
            <div class="main3-product-detail-dad w-90">
              <div class="main3-product-detail-txt">ÄHNLICHE PRODUKTE</div>
              <div class="flex-j-s">
                <div class="main3-product-detail-box">
                  <img src={img3} alt="" />
                  <div class="main2-home-txt1-pro">Blöcke</div>
                  <div class="main2-home-txt2-pro">
                    {/* <a href="#">3 Produkte</a> */}
                  </div>
                </div>
                <div class="main3-product-detail-box">
                  <img src={img4} alt="" />
                  <div class="main2-home-txt1-pro">
                    Broschüren/ Zeitungen/ Magazine
                  </div>
                  <div class="main2-home-txt2-pro">
                    {/* <a href="#">2 Produkte</a> */}
                  </div>
                </div>
                <div class="main3-product-detail-box">
                  <img src={img5} alt="" />
                  <div class="main2-home-txt1-pro">Buchdruck</div>
                  <div class="main2-home-txt2-pro">
                    {/* <a href="3">4 Produkte</a> */}
                  </div>
                </div>
                <div class="main3-product-detail-box">
                  <img src={img6} alt="" />
                  <div class="main2-home-txt1-pro">Bürodruck Sorten</div>
                  <div class="main2-home-txt2-pro">
                    {/* <a href="#"> 19 Produkte</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
