import React, { Component, Fragment } from "react";

import notif from "../../../../utils/notif";

//redux
import { connect } from "react-redux";
import store from "../../../../store/store";
import {
  removeCart,
  // getCart,
  // updateCart,
  addCart,
  reduce,
} from "../../../../action";

import img1 from "../../../../assets/images/kam.png";

// import Heart from "../../../../assets/svg/heart";
// import Share from "../../../../assets/svg/share";

import { setAuthToken } from "../../../../api/_api";

//api
import { order } from "../../../../api/Api";

class Cart1 extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello
    this.unSub = store.subscribe(this.sub);

    this.getOrder();
  }

  unSub;
  sub = async () => {
    let reduxCart = store.getState();
    this.setState({ reduxCart });
  };

  componentWillUnmount() {
    this.unSub();
  }

  getOrder = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthToken(token);
        const { data, status } = await order.getOrder();

        if (status === 200) {
          let totalPrice = 0;
          data.data.orderItems.forEach((element) => {
            totalPrice += element.quantity * element.productPricing.price;
          });
          this.setState({ totalPrice });
          this.props.getTotalPrice(totalPrice);
          this.setState({ order: data.data.orderItems });
          this.props.getCartData(data.data.orderItems);
        } else if (status === 204) {
          this.setState({ order: [], totalPrice: 0 });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  reduce = (x) => {
    let token = localStorage.getItem("token");
    if (!token) {
      notif(" برای خرید محصول لطفا ابتدا لاگین کنید");
    } else this.props.Reduce(x);
  };

  addToCart = async (item, count) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthToken(token);
      let newOrder = [...this.state.order];

      const cartIndex = newOrder.findIndex((x) => {
        return String(x.productPricingId) === String(item.id);
      });

      if (cartIndex === -1) {
        const object = {
          productPricingId: item.id,
          quantity: count,
        };
        newOrder.push(object);
      } else {
        newOrder[cartIndex].quantity = count;
      }

      await order.sendOrder(newOrder);
      this.getOrder();
    } else {
      notif(" برای خرید محصول لطفا ابتدا لاگین کنید");
    }
  };

  clearCart = async () => {
    try {
      await order.clearOrder();
      this.getOrder();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <Fragment>
        <div class="m5-bl d-r flex">
          <div class="m5-bl1 flex p-r">
            <div class="m5-bl1l flex-j-s ">
              <div class="m5-bl1lt flex">
                <i class="mdi mdi-clipboard-text-outline"></i>
              </div>
              <div class="m5-bl1lm flex-j-s">
                <div class="m5-bl1lml">سفارشات</div>
                <div class="m5-bl1lmr">{this.props.allReport}</div>
              </div>
              <div class="m5-bl1lb">
                <p
                  class="cursorP"
                  onClick={() => {
                    this.props.handlePage("orders");
                    this.props.showOrders(this.props.resultData);
                  }}
                >
                  مشاهده همه
                  <nbsp class="m5-bl1lbi">
                    <i class=" mdi mdi-arrow-left"></i>
                  </nbsp>
                </p>
              </div>
            </div>

            <div class="m5-bl1l flex-j-s">
              <div class="m5-bl1mt flex">
                <img alt="" src={img1} id="m5-bl1mt" />
              </div>
              <div class="m5-bl1mm flex-j-s">
                <div class="m5-bl1mml">در انتظار پرداخت</div>
                <div class="m5-bl1mmr">
                  {this.props.paymentPending &&
                    this.props.paymentPending.length}
                </div>
              </div>
              <div class="m5-bl1mb">
                <p
                  class="cursorP"
                  onClick={() => {
                    this.props.handlePage("orders");
                    this.props.showOrders(this.props.paymentPending);
                  }}
                >
                  مشاهده همه
                  <nbsp class="m5-bl1lbi">
                    <i class=" mdi mdi-arrow-left"></i>
                  </nbsp>
                </p>
              </div>
            </div>

            <div class="m5-bl1l flex-j-s">
              <div class="m5-bl1rt flex">
                <i class="mdi mdi-cached"></i>
              </div>
              <div class="m5-bl1rm flex-j-s">
                <div class="m5-bl1rml">پذیرفته شده</div>
                <div class="m5-bl1rmr">
                  {" "}
                  {this.props.submitted && this.props.submitted.length}
                </div>
              </div>
              <div class="m5-bl1rb">
                <p
                  onClick={() => {
                    this.props.handlePage("orders");
                    this.props.showOrders(this.props.submitted);
                  }}
                >
                  مشاهده همه
                  <nbsp class="m5-bl1lbi">
                    <i class=" mdi mdi-arrow-left"></i>
                  </nbsp>
                </p>
              </div>
            </div>
          </div>

          <div class="m5-bl2 flex-j-s">
            <div class="m5-bl2r">آخرین سفارشات ثبت شده</div>
            <div class="m5-bl2l">مشاهده همه</div>
          </div>

          <div class="m5-bl3 flex-j-c">
            <ul class="m5-bl3-1 flex-j-s">
              <li class="m5-bl3-1l"> شماره سفارش</li>
              <li> تاریخ سفارش</li>
              <li> قیمت</li>
              <li> وضعیت سفارش</li>
              <li class="m5-bl3-1a"> مشاهده جزئیات</li>
            </ul>

            {this.props.lastReport &&
              this.props.lastReport.map((x) => {
                return (
                  <ul class="m5-bl3-2 flex-j-s">
                    <li class="m5-bl3-2l"> 9874210</li>
                    <li class="m5-bl3-2d"> 1399/06/26</li>
                    <li class="m5-bl3-2e"> {x.finalPrice} تومان</li>
                    <li class="m5-bl3-2h">
                      <i class="mdi mdi-truck-outline"></i>
                      {x.status &&
                      x.status.name &&
                      x.status.name === "PAYMENT_PENDING"
                        ? "در انتظار پرداخت"
                        : x.status.name === "NOT_DELIVERED"
                        ? "ارسال نشده"
                        : x.status.name === "SUBMITTED"
                        ? "پذيرفته شده"
                        : ""}
                    </li>
                    <li class="m5-bl3-2a">
                      <i
                        onClick={() =>
                          this.props.handlePage("thisOrder", x.orderId)
                        }
                        class="mdi mdi-chevron-left cursorP"
                      ></i>
                    </li>
                  </ul>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  AddCart: function (value, price) {
    return dispatch(addCart(value, price));
  },
  RemoveCart: function (value) {
    return dispatch(removeCart(value));
  },
  Reduce: function (value) {
    return dispatch(reduce(value));
  },
  // UpdateCart: function (value) {
  //   return dispatch(updateCart(value));
  // },
});

export default connect(mapStatetoProps, mapDispatchToProps)(Cart1);
