import React, { Component, Fragment } from "react";

import notif from "../../../../utils/notif";

//redux
import { connect } from "react-redux";
import store from "../../../../store/store";
import {
  removeCart,
  // getCart,
  // updateCart,
  addCart,
  reduce,
} from "../../../../action";

import { setAuthToken } from "../../../../api/_api";

//api
import { order } from "../../../../api/Api";

class Cart1 extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello
    this.unSub = store.subscribe(this.sub);

    this.getOrder();
  }

  unSub;
  sub = async () => {
    let reduxCart = store.getState();
    this.setState({ reduxCart });
  };

  componentWillUnmount() {
    this.unSub();
  }

  getOrder = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthToken(token);
        const { data, status } = await order.getOrder();

        if (status === 200) {
          let totalPrice = 0;
          data.data.orderItems.forEach((element) => {
            totalPrice += element.quantity * element.productPricing.price;
          });
          this.setState({ totalPrice });
          this.props.getTotalPrice(totalPrice);
          this.setState({ order: data.data.orderItems });
          this.props.getCartData(data.data.orderItems);
        } else if (status === 204) {
          this.setState({ order: [], totalPrice: 0 });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  reduce = (x) => {
    let token = localStorage.getItem("token");
    if (!token) {
      notif(" برای خرید محصول لطفا ابتدا لاگین کنید");
    } else this.props.Reduce(x);
  };

  addToCart = async (item, count) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthToken(token);
      let newOrder = [...this.state.order];

      const cartIndex = newOrder.findIndex((x) => {
        return String(x.productPricingId) === String(item.id);
      });

      if (cartIndex === -1) {
        const object = {
          productPricingId: item.id,
          quantity: count,
        };
        newOrder.push(object);
      } else {
        newOrder[cartIndex].quantity = count;
      }

      await order.sendOrder(newOrder);
      this.getOrder();
    } else {
      notif(" برای خرید محصول لطفا ابتدا لاگین کنید");
    }
  };

  clearCart = async () => {
    try {
      await order.clearOrder();
      this.getOrder();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <Fragment>
        {" "}
        <main>
          <div class="m5 flex-j-c w-100">
            <div class="m5-b flex-j-s w1-b">
              <div class="m9-bl d-r flex">
                <div class="m9-blt">
                  {" "}
                  <p>علاقه مندی ها</p>
                </div>
                <div class="m12-blb flex ">
                  <div class="mm12 flex-j-c wrap w-90 ">
                    <div class="m12-blc1 flex ">
                      <div class="m12-blc1-1 flex-j-s p-r">
                        <div class="m12-blc1-1i ">
                          <i class="m12-blc1-1i1 mdi mdi-heart"></i>
                          <img alt="" src="./index2.img/1.png" />
                        </div>
                        <div class="m12-blc1-1n" id="m4-blc1-1n">
                          غذای خشک سگ جوان ده کیلوگرمی، برند مفید
                        </div>
                        <div class="m12-blc1-1d">
                          {" "}
                          <span class="m12-blc1-1ds">480.000 </span> تومان
                        </div>
                        <div class="m12-blc1-1m"></div>
                        <div class="m12-blc1-1a">
                          {" "}
                          <span class="m12-blc1-1as">97.000 </span> تومان
                        </div>
                      </div>
                      <div class="m12-blc1-1 flex-j-s p-r">
                        <div class="m12-blc1-1i">
                          <i class="m12-blc1-1i2 mdi mdi-heart"></i>
                          <img alt="" src="./index2.img/2.png" />
                        </div>
                        <div class="m12-blc1-1n">
                          ظرف آب و غذای استیل مخصوص سگ و گربه
                        </div>
                        <div class="m12-blc1-1d">
                          {" "}
                          <span class="m12-blc1-1ds">480.000 </span>تومان
                        </div>
                        <div class="m12-blc1-1m"></div>
                        <div class="m12-blc1-1a">
                          {" "}
                          <span class="m12-blc1-1as">235.000 </span>تومان
                        </div>
                      </div>
                      <div class="m12-blc1-1 flex-j-s p-r">
                        <div class="m12-blc1-1i">
                          <i class="m12-blc1-1i3 mdi mdi-heart"></i>
                          <img alt="" src="./index2.img/3.png" />
                        </div>
                        <div class="m12-blc1-1n">
                          لانه سه گوش، مناسب سگ و گربه های کوچک
                        </div>
                        <div class="m12-blc1-1d">
                          {" "}
                          <span class="m12-blc1-1ds">480.000 </span> تومان
                        </div>
                        <div class="m12-blc1-1m"></div>
                        <div class="m12-blc1-1a">
                          {" "}
                          <span class="m12-blc1-1as">235.000 </span> تومان
                        </div>
                      </div>
                      <div class="m12-blc1-1 flex-j-s p-r">
                        <div class="m12-blc1-1i ">
                          <i class="m12-blc1-1i4 mdi mdi-heart"></i>
                          <img alt="" src="./index2.img/4.png" />
                        </div>
                        <div class="m12-blc1-1n">
                          سوپ مخصوص گربه بالغ حاوی گوشت گوساله
                        </div>
                        <div class="m12-blc1-1d">
                          {" "}
                          <span class="m12-blc1-1ds">480.000 </span> تومان
                        </div>
                        <div class="m12-blc1-1m"></div>
                        <div class="m12-blc1-1a">
                          {" "}
                          <span class="m12-blc1-1as">235.000 </span> تومان
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>{" "}
      </Fragment>
    );
  }
}

function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  AddCart: function (value, price) {
    return dispatch(addCart(value, price));
  },
  RemoveCart: function (value) {
    return dispatch(removeCart(value));
  },
  Reduce: function (value) {
    return dispatch(reduce(value));
  },
  // UpdateCart: function (value) {
  //   return dispatch(updateCart(value));
  // },
});

export default connect(mapStatetoProps, mapDispatchToProps)(Cart1);
