import React, { Component, Fragment } from "react";

// API
import { account } from "../../../../api/Api";

import { setAuthToken } from "../../../../api/_api";

// Components

export default class LoginForm extends Component {
  state = {
    expireInSeconds: 180,
    id1: true,
    id2: false,
    id3: false,
    id4: false,

    plus: "",
    initialValues: {
      phoneNumber:
        this.props &&
        this.props.data &&
        this.props.data.loginData &&
        this.props.data.loginData.phoneNumber
          ? this.props.data.loginData.phoneNumber
          : localStorage.getItem("phoneNumber"),
    },
  };

  handleSubmit = async () => {
    try {
      let newValues = {
        phoneNumber: this.state.initialValues.phoneNumber,
        verifyCode: this.state.plus,
      };
      let data = await account.verify(newValues);
      if (data.data && data.data.data && data.data.data.token) {
        localStorage.setItem("token", data.data.data.token);
        localStorage.setItem("data", JSON.stringify(data.data));
        localStorage.setItem(
          "phoneNumber",
          this.state.initialValues.phoneNumber
        );
        setAuthToken(data.data.data.token);
        this.props.open("success", "تایید", "ورود با موفقیت انجام شد");
        this.props.redirect();
      }

      // this.props.loginData(this.state);
      // this.props.goNext();
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.time();

    // set Interval
    this.interval = setInterval(this.time, 1000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  time = async () => {
    await this.setState({
      expireInSeconds: Number(this.state.expireInSeconds - 1),
    });
  };

  // ValidationSchema = Yup.object().shape({
  //   phoneNumber: Yup.string()
  //     .required()
  //     .min(11)
  //     .max(11)
  //     .matches(
  //       /^(09)(10|11|12|13|14|15|16|17|18|19|90|91|32|30|33|35|36|37|38|39|01|02|03|04|05|41|20|21|22)\d{7}/,
  //       "لطفا شماره موبایل معتبر وارد نمایید"
  //     ),
  // });

  backPage = () => {
    this.props.goNext(1);
    localStorage.setItem("status", "");
    localStorage.setItem("phoneNumber", "");
  };

  // onChange =async (name, value) => {
  //  await this.setState({
  //     initialValue: { ...this.state.initialValue, [name]: value },
  //   });
  // };

  onChange = async (name, value) => {
    if (name === "4" && value === "") {
      document.getElementById("id3").focus();
    } else if (name === "3" && value === "") {
      document.getElementById("id2").focus();
    } else if (name === "2" && value === "") {
      document.getElementById("id1").focus();
    }
    if (name === "1" && value) {
      document.getElementById("id2").focus();
    } else if (name === "2" && value) {
      document.getElementById("id3").focus();
    } else if (name === "3" && value) {
      document.getElementById("id4").focus();
    }
    await this.setState({
      numbers: { ...this.state.numbers, [name]: value },
    });
    let plus =
      this.state.numbers[1] +
      this.state.numbers[2] +
      this.state.numbers[3] +
      this.state.numbers[4];

    this.setState({ plus });
  };

  backPage = () => {
    this.props.goNext(1);
    localStorage.setItem("status", "");
    localStorage.setItem("phoneNumber", "");
  };

  render() {
    // const phoneNumber = localStorage.getItem("phoneNumber");
    // if (status ==="Created") {
    //   this.props.goNext(2);
    // }
    return (
      <Fragment>
        <div className="mcart1-l flex-j-c">
          <div className="mainlil1">
            <div>
              <ul className="flex-j-s">
                <li className="ullil3 d-r">
                  {this.props.totalPrice}
                  <span>ریال</span>
                </li>
                <li className="ullil2">........</li>
                <li className="ullil1">قیمت کالاها</li>
              </ul>
            </div>
            <div>
              <ul className="flex-j-s">
                <li className="ullil3-2 d-r">
                  0<span>ریال</span>
                </li>
                <li className="ullil2-2">........</li>
                <li className="ullil1">تخفیف کالاها</li>
              </ul>
            </div>
            {/* <div>
                  <ul className="flex-j-s">
                    <li className="ullil3 d-r">
                      8.000 <span>ریال</span>
                    </li>
                    <li className="ullil2-3">........</li>
                    <li className="ullil1">هزینه ارسال</li>
                  </ul>
                </div> */}
            <div className="gifc flex-j-s">
              <div>
                <input className="putcg" placeholder="کد تخفیف" />
              </div>
              <div>
                <i className="mdi mdi-gift-outline icngc"></i>
              </div>
            </div>
            <div className="boxct d-r">
              مبلغ قابل پرداخت <br />
              <span className="numbrc">
                {this.props.totalPrice} <span className="tmnc">ریال</span>
              </span>
            </div>
            <div className="textct1 flex-j-c">
              کالاهای موجود در سبد شما ثبت و رزرو نشده‌اند، برای ثبت سفارش مراحل
              بعدی را تکمیل کنید
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
