import React, { Component, Fragment } from "react";

// API
import { paiment } from "../../../../api/Api";

export default class LoginForm extends Component {
  state = {};

  handleSubmit = async () => {
    try {
      let newValues = {
        invoiceId: this.props.shibmentData.invoiceId,
      };
      const data = await paiment.sendPayment(newValues);
      this.props.open("success", "تایید", "به درگاه پرداخت متصل میشوید");
      window.location.href = data.data.data.link;
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <div class="mcart1-r-3 flex-j-c">
          <div class="mc1ur">
            <ul class="ulc1 flex-j-c">
              <li>
                <i class="mdi mdi-credit-card-outline" id="icncredit"></i>
              </li>
              <li class="cdot1" id="cdot1-3">
                ........
              </li>
              <li>
                <i class="mdi mdi-map-marker-outline" id="icnmarker"></i>
              </li>
              <li class="cdot1" id="cdot1-2">
                ........
              </li>
              <li>
                <i class="mdi mdi-cart-variant" id="icnvariant"></i>
              </li>
            </ul>
            <ul class="ulc2 flex-j-s">
              <li onClick={() => this.props.goNext("step3")}>
                <p id="shive-pardakht">انتخاب شیوه پرداخت </p>
              </li>
              <li onClick={() => this.props.goNext("step2")}>
                <p id="entekhab-shive">انتخاب شیوه ارسال</p>
              </li>
              <li onClick={() => this.props.goNext("step1")}>
                <p id="sabadkharid">تکمیل سبد خرید</p>
              </li>
            </ul>
            {/* <div class="cart3m">سبد خرید</div>
            <div class="cart3m-2 flex-j-s">
              <div class="cart3m-2-l flex-j-s">
                <div class="cart3m-2-ru">
                  <div class="cart3m-2-ru1 d-r">2 عدد</div>
                  <div class="cart3m-2-ru2 d-r">
                    <span>235.000</span> تومان
                  </div>
                </div>
                <div class="cart3m-2-rm">
                  لانه سه گوش، مناسب سگ وگربه های کوچک
                </div>
                <div class="cart3m-2-rd">
                  <img src="./index.img/img2.png" />
                </div>
              </div>
              <div class="cart3m-2-l flex-j-s">
                <div class="cart3m-2-ru">
                  <div class="cart3m-2-ru1 d-r">2 عدد</div>
                  <div class="cart3m-2-ru2 d-r">
                    <span>235.000</span> تومان
                  </div>
                </div>
                <div class="cart3m-2-rm">
                  غذای خشک سگ جوان ده کیلوگرمی، برند مفید
                </div>
                <div class="cart3m-2-rd">
                  <img src="./index.img/img1.png" />
                </div>
              </div>
            </div>
            <div class="cart3m-4">
              <div class="cart3-4b flex-j-s">
                <div class="cart3m-2-ru">
                  <div class="cart3m-2-ru1 d-r">2 عدد</div>
                  <div class="cart3m-2-ru2 d-r">
                    <span>235.000</span> تومان
                  </div>
                </div>
                <div class="cart3m-2-rm">
                  لانه سه گوش، مناسب سگ وگربه های کوچک
                </div>
                <div class="cart3m-2-rd">
                  <img src="./index.img/img3.png" />
                </div>
              </div>
            </div> */}
            <div class="cart3m">آدرس ارسال</div>
            <div class="mcart2-4">
              <div class="flex-j-s">
                <div class="circlect2"></div>
                <div>
                  <div class="txtct2-2">
                    <span>
                      {this.props.shibmentData.destinationAddress.name}
                    </span>
                    : نام انتخابی{" "}
                  </div>
                  <div class="txtct3-3">
                    {this.props.shibmentData.destinationAddress.description}
                  </div>
                </div>
              </div>
              <div>
                <div class="mcart2-2 flex-j-s">
                  <div
                    onClick={() => this.props.goNext("step2")}
                    class="cart3add"
                  >
                    {/* تغییر آدرس ارسال */}
                  </div>
                  <div class="txtct2-4">
                    کد پستی:{" "}
                    <span>
                      {" "}
                      {this.props.shibmentData.destinationAddress.postalCode}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="cart3m">نوع ارسال</div>
            <div class="cart3-box flex-j-s">
              <div class="cr3-bxl">{/* تغییر نوع ارسال */}</div>
              <div class="flex-j-s">
                <div class="m3txtbx3 d-r">
                  {" "}
                  <span>{this.props.shibmentData.shipment.cost}</span> تومان
                </div>
                <div class="m3txtbx">
                  {this.props.shibmentData.shipment.name === "BUYING_IN_PERSON"
                    ? "رفتن به فروشگاه"
                    : "فرستادن با پیک"}
                  <br /> <span></span>
                </div>
              </div>
            </div>
            {/* <div class="cart3m">درگاه بانکی</div>
            <div class="cart3-box flex-j-s">
              <div class="melatc3">
                <ul class="ulcrt3 flex-j-s">
                  <li class="crt3-3">
                    <p>بانک ملت</p>
                  </li>
                  <li class="crt3-2">
                    <img src="./index.img/Mellat 1.png" />
                  </li>
                  <li class="crt3-1"></li>
                </ul>
              </div>
              <div>
                <ul class="ulcrt3 flex-j-s">
                  <li class="crt3-3">
                    <p>بانک سامان</p>
                  </li>
                  <li class="crt3-2">
                    <img src="./index.img/saman-bank2 1.png" />
                  </li>
                  <li class="crt3-1"></li>
                </ul>
              </div>
            </div> */}
            <div class="mcart5 d-l">
              <div class="mcart5-b flex-j-s">
                <div class="mcbut1">
                  <button onClick={this.handleSubmit}>
                    <i class="mdi mdi-less-than"></i> تایید نهایی و پرداخت
                  </button>
                </div>
                <div class="mctxt d-r">
                  <div class="mctxt1"> مبلغ قابل پرداخت</div>
                  <span class="mctxtsp">{this.props.totalPrice} </span>{" "}
                  <span class="mctxtsp1">ریال</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
