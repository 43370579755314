import React, { Component, Fragment } from "react";
import { Button } from "rsuite";

// API
import { address, paiment } from "../../../../api/Api";

// Components

import AddAddress from "../../../../components/address";

export default class LoginForm extends Component {
  state = {
    address: [],
    show: false,
    constantShipment: [],
    settings: [{ id: "", open: false }],
    settingsShipment: [{ id: "", open: false }],
  };

  componentDidMount() {
    this.getAddress();
    this.getAllConstantShipment();
  }

  getAddress = async () => {
    const data = await address.getAll();

    if (data.status === 204) {
      this.setState({ address: [] });
    } else {
      this.setState({ address: data.data.data });
      await this.setState((state) => ({
        ...state,
        destinationAddressId: data.data.data[0].id,
        settings: data.data.data.map((item) =>
          item.id === data.data.data[0].id
            ? { ...item, open: !item.open }
            : { ...item, open: false }
        ),
      }));
    }
  };

  getAllConstantShipment = async () => {
    const data = await address.getAllConstantShipment();

    if (data.status === 204) {
      this.setState({ constantShipment: [] });
    } else {
      this.setState({ constantShipment: data.data.data });
      await this.setState((state) => ({
        ...state,
        shipmentId: data.data.data[0].id,
        settingsShipment: data.data.data.map((item) =>
          item.id === data.data.data[0].id
            ? { ...item, open: !item.open }
            : { ...item, open: false }
        ),
      }));
    }
  };

  onClickAddress = async (id) => {
    await this.setState((state) => ({
      ...state,
      destinationAddressId: id,
      settings: state.address.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  onClickShipment = async (id) => {
    await this.setState((state) => ({
      ...state,
      shipmentId: id,
      settingsShipment: state.constantShipment.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  handleChangePage = async () => {
    if (this.state.address.length) {
      const invoice = await paiment.sendInvoice();

      if (invoice.status === 201) {
        let newValues = {
          shipmentType: "ConstantShipment",
          shipmentId: this.state.shipmentId,
          destinationAddressId: this.state.destinationAddressId,
          invoiceId: invoice.data.data.id,
        };
        const data = await paiment.sendShipment(newValues);
        if (data.status === 201) {
          this.props.getShibmentData(data.data.data);
          this.props.goNext("step3");
          this.props.openNotif("success", "تایید", "با موفقیت انجام گرفت");
        }
      }
    } else this.props.openNotif("error", "خطا", "لطفا ادرسی اضافه کنید");
  };

  close = () => {
    this.setState({
      show: false,
    });
  };
  open = () => {
    this.setState({
      show: true,
    });
  };

  removeAddress = async (id) => {
    try {
      // const data =
      await address.deleteAddress(id);
      this.props.openNotif("success", "تایید", "با موفقیت حذف گردید");
      this.getAddress();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <Fragment>
        <AddAddress
          openNotif={this.props.openNotif}
          open={this.open}
          close={this.close}
          show={this.state.show}
          getAddress={this.getAddress}
        />
        <div class="mcart1-r-2 flex-j-c">
          <div class="mc1ur">
            <ul class="ulc1 flex-j-c">
              <li>
                <i class="mdi mdi-credit-card-outline"></i>
              </li>
              <li class="cdot1">........</li>
              <li>
                <i class="mdi mdi-map-marker-outline" id="icnmarker"></i>
              </li>
              <li class="cdot1" id="cdot1-2">
                ........
              </li>
              <li>
                <i class="mdi mdi-cart-variant" id="icnvariant"></i>
              </li>
            </ul>
            <ul class="ulc2 flex-j-s">
              <li onClick={() => this.props.goNext("step3")}>
                <p>انتخاب شیوه پرداخت </p>
              </li>
              <li onClick={() => this.props.goNext("step2")}>
                <p id="entekhab-shive">انتخاب شیوه ارسال</p>
              </li>
              <li onClick={() => this.props.goNext("step1")}>
                <p id="sabadkharid">تکمیل سبد خرید</p>
              </li>
            </ul>

            {this.state.address.map((x) => {
              return (
                <div onClick={() => this.onClickAddress(x.id)} class="mcart2-1">
                  <div class="flex-j-s">
                    <div class="circlect2">
                      {this.state.settings.find((item) => item.id === x.id) &&
                      this.state.settings.find((item) => item.id === x.id)
                        .open ? (
                        <i
                          style={{ color: "rgba(252, 185, 19, 1)" }}
                          class="mdi mdi-check-circle"
                        ></i>
                      ) : (
                        <i class="mdi mdi-circle-outline"></i>
                      )}
                    </div>
                    <div>
                      <div class="txtct2-2">
                        <span>{x.name}</span>: نام انتخابی
                      </div>
                      <div class="txtct3-3">{x.description}</div>
                    </div>
                  </div>
                  <div>
                    <div class="mcart2-2 flex-j-s">
                      <div class="flex-j-s">
                        <div
                          onClick={() => this.removeAddress(x.id)}
                          class="txtad-2 cursorP"
                        >
                          حذف آدرس <i class="mdi mdi-delete-outline"></i>{" "}
                        </div>
                        {/* <div class="txtad-1">
                          ویرایش آدرس <i class="mdi mdi-pencil-outline"></i>
                        </div> */}
                      </div>
                      <div class="txtct2-4">
                        کد پستی: <span> {x.postalCode}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div class="mcart2-button">
              <div class="flex-j-s">
                <div class="butcrt2">
                  <button onClick={this.open} type="submit">
                    افزودن آدرس جدید <i class="mdi mdi-map-marker-outline"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="mcart2-1-send">
              <div class="mcart4 d-r">
                <div class="txtnoc2">نوع ارسال</div>
                <div class="flex-j-s">
                  {this.state.constantShipment.map((x) => {
                    return (
                      <div
                        onClick={() => this.onClickShipment(x.id)}
                        class="mcart4-3a"
                      >
                        <div class="flex-j-s">
                          <div class="mcart4-2 flex-j-s">
                            <div class="mcart4-2u">
                              {this.state.settingsShipment.find(
                                (item) => item.id === x.id
                              ) &&
                              this.state.settingsShipment.find(
                                (item) => item.id === x.id
                              ).open ? (
                                <i
                                  style={{ color: "rgba(252, 185, 19, 1)" }}
                                  class="mdi mdi-check-circle"
                                ></i>
                              ) : (
                                <i class="mdi mdi-circle-outline"></i>
                              )}
                            </div>
                            <div class="mcart4-2m">
                              <div class="txtpo4">
                                {" "}
                                {x.name === "BUYING_IN_PERSON"
                                  ? "رفتن به فروشگاه"
                                  : "فرستادن با پیک"}
                              </div>
                            </div>
                            <div class="mcart4-2d flex-j-s d-r">
                              <div class="txter4"></div>
                              <div class="txter4-2">
                                {" "}
                                {x.cost} <span> تومان </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div class="mcart5 d-l">
                  <div class="mcart5-b flex-j-s">
                    <div class="mcbut1">
                      <Button
                        color="yellow"
                        // disabled={!this.state.address.length}
                        onClick={this.handleChangePage}
                      >
                        <i class="mdi mdi-less-than"></i> انتخاب شیوه پرداخت
                      </Button>
                    </div>
                    <div class="mctxt d-r">
                      <div class="mctxt1"> مبلغ قابل پرداخت</div>
                      <span class="mctxtsp">{this.props.totalPrice} </span>{" "}
                      <span class="mctxtsp1">ریال</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
