import React, { Component, Fragment } from "react";

// API
import { address, paiment } from "../../../../api/Api";

// Components

export default class LoginForm extends Component {
  state = {
    address: [],
    show: false,
    constantShipment: [],
    settings: [{ id: "", open: false }],
    settingsShipment: [{ id: "", open: false }],
  };

  componentDidMount() {
    this.getAddress();
    this.getAllConstantShipment();
  }

  getAddress = async () => {
    const data = await address.getAll();

    if (data.status === 204) {
      this.setState({ address: [] });
    } else {
      this.setState({ address: data.data.data });
      await this.setState((state) => ({
        ...state,
        destinationAddressId: data.data.data[0].id,
        settings: data.data.data.map((item) =>
          item.id === data.data.data[0].id
            ? { ...item, open: !item.open }
            : { ...item, open: false }
        ),
      }));
    }
  };

  getAllConstantShipment = async () => {
    const data = await address.getAllConstantShipment();

    if (data.status === 204) {
      this.setState({ constantShipment: [] });
    } else {
      this.setState({ constantShipment: data.data.data });
      await this.setState((state) => ({
        ...state,
        shipmentId: data.data.data[0].id,
        settingsShipment: data.data.data.map((item) =>
          item.id === data.data.data[0].id
            ? { ...item, open: !item.open }
            : { ...item, open: false }
        ),
      }));
    }
  };

  onClickAddress = async (id) => {
    await this.setState((state) => ({
      ...state,
      destinationAddressId: id,
      settings: state.address.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  onClickShipment = async (id) => {
    await this.setState((state) => ({
      ...state,
      shipmentId: id,
      settingsShipment: state.constantShipment.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  handleChangePage = async () => {
    if (this.state.address.length) {
      const invoice = await paiment.sendInvoice();

      if (invoice.status === 201) {
        let newValues = {
          shipmentType: "ConstantShipment",
          shipmentId: this.state.shipmentId,
          destinationAddressId: this.state.destinationAddressId,
          invoiceId: invoice.data.data.id,
        };
        const data = await paiment.sendShipment(newValues);
        if (data.status === 201) {
          this.props.getShibmentData(data.data.data);
          this.props.goNext("step3");
          this.props.openNotif("success", "تایید", "با موفقیت انجام گرفت");
        }
      }
    } else this.props.openNotif("error", "خطا", "لطفا ادرسی اضافه کنید");
  };

  close = () => {
    this.setState({
      show: false,
    });
  };
  open = () => {
    this.setState({
      show: true,
    });
  };

  removeAddress = async (id) => {
    try {
      await address.deleteAddress(id);
      this.props.openNotif("success", "تایید", "با موفقیت حذف گردید");
      this.getAddress();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="m7-bl d-r flex">
            <div className="m7-blt">
              <p>سفارشات</p>
            </div>
            <div className="m7-blb flex wrap">
              {this.props.ordersForShow.length ? (
                this.props.ordersForShow.map((x) => {
                  return (
                    <div
                      onClick={() =>
                        this.props.handlePage("thisOrder", x.orderId)
                      }
                      className="m7-blb1 cursorP"
                    >
                      <div className="m7-blb1s ">
                        <p className="flex-j-s margin-s">
                          <div> آدرس</div>
                          <div>
                            <span className="m7-blb1sn">..........</span>
                          </div>
                          <div>{x.destinationAddress.description}</div>
                        </p>
                        <p className="flex-j-s margin-s">
                          <div> کد پستی</div>
                          <div>
                            <span className="m7-blb1sn">..........</span>
                          </div>
                          <div>{x.destinationAddress.postalCode}</div>
                        </p>
                      </div>
                      <div className="m7-blb1p flex-j-s ">
                        <div className="m7-blb1p3">
                          <i className=" m7-blb1p3i mdi mdi-card-text-outline"></i>
                          {x.status &&
                          x.status.name &&
                          x.status.name === "PAYMENT_PENDING"
                            ? "در انتظار پرداخت"
                            : x.status.name === "NOT_DELIVERED"
                            ? "ارسال نشده"
                            : x.status.name === "SUBMITTED"
                            ? "پذيرفته شده"
                            : ""}
                        </div>
                        <div className="m7-blb1p1">
                          {x.finalPrice}{" "}
                          <span className="m7-blb1p2">تومان</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex-j-c margin-s">
                  <div> سفارشی ثبت نشده است</div>
                </div>
              )}
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
