import React, { Component, Fragment } from "react";

// API
import { order } from "../../../../api/Api";

export default class LoginForm extends Component {
  state = { data: [], totalPrice: 0 };

  componentDidMount() {
    this.getOrder();
  }

  getOrder = async () => {
    try {
      const data = await order.getOneOrderDetails(this.props.orderId);
      if (data.status === 200) {
        this.setState({ data: data.data.data });
        let totalPrice = 0;
        data.data.data.forEach((element) => {
          totalPrice += element.quantity * element.productPricing.price;
        });
        this.setState({ totalPrice });
      }
    } catch (error) {}
  };

  close = () => {
    this.setState({
      show: false,
    });
  };
  open = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    return (
      <Fragment>
        <main>
          <div class="mordr-bl d-r flex">
            <div class="m1ordr flex-j-s">
              <div class="m1ordr-l"> جزئیات سفارش</div>
              {/* <div class="m1ordr-r">
                <i class="mdi mdi-window-close"></i>لغو کردن سفارش
              </div> */}
            </div>
            <div class="flex-j-s">
              <div class="w-60">
                <div class="txordr1"> سبد خریداری شده</div>
                <div class="ordrboxb flex wrap">
                  {this.state.data.map((x) => {
                    return (
                      <div class="ordbox1 flex-j-s">
                        <div class="ordimg1">
                          <img
                            alt=""
                            src={x.productPricing.product.images[0].url}
                          />
                        </div>
                        <div class="flexd flex-j-s">
                          <div class="ordtxtd">
                            {x.productPricing.product.name}
                          </div>
                          <div class="flex-j-s">
                            <div class="ordr-ru2 d-r">
                              <span>{x.productPricing.price}</span> تومان
                            </div>
                            <div class="ordr-ru1">{x.quantity} عدد</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <div class="order3m">آدرس ارسال</div>
                <div class="order4m">
                  <div class="flex-j-s">
                    <div>
                      <div class="txtct2-2">
                        نام تحویل گیرنده: <span>فاطمه کریمی</span>
                      </div>
                      <div class="txtct3-3">
                        تهران، خیابان معین، روبروی خیابان معین یازدهم، مجتمع بام
                        پونک، طبقه 2، واحد 5
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="mcart2-2 flex-j-s">
                      <div class="txtct2-4">
                        کد پستی: <span> 3716176542</span>
                      </div>
                    </div>
                    <div class="order3m">نوع ارسال</div>
                    <div class="order5m d-l">
                      <div class="flex-j-s">
                        <div class="m3txtbx3ord d-r">
                          <span>10/000</span> تومان
                        </div>
                        <div class="m3txtbx">
                          پست پیشتاز <br /> <span>ارسال 2 الی 3 روز کاری</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div class="mordr4b w-40 d-r">
                <div class="txordr2">فاکتور </div>
                <div class="boxord3 flex-j-s flexd">
                  <div class="boxord3-2">
                    <div class="flex-j-s">
                      <div class="txtordr4">مبلغ کل</div>
                      <div class="numord1 d-r">
                        {this.state.totalPrice} <span>تومان</span>
                      </div>
                    </div>
                    {/* <div class="flex-j-s">
                      <div class="txtordr4">هزینه ارسال</div>
                      <div class="numord1 d-r">
                        10/000 <span>تومان</span>
                      </div>
                    </div> */}
                    {/* <div class="flex-j-s">
                      <div class="txtordr4" id="txto4">
                        میزان تخفیف
                      </div>
                      <div class="numord1 d-r" id="txto4-2">
                        27/000 <span>تومان</span>
                      </div>
                    </div> */}
                  </div>
                  <div class="butord2 flex-j-s">
                    <div class="butord2-txt1"> مبلغ پرداختی</div>
                    <div class="butord2-txt2 d-r">
                      {this.state.totalPrice} <span>تومان</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
