import { APIClient } from "./_api";

export function getAllEndFormat() {
  return APIClient.get(`${"Button_EndFormat"}`);
}

export function digiPrintMaterial(value) {
  return APIClient.post(`${"Button_Record/material"}`, value);
}
export function record(value) {
  let bodyFormData = new FormData();
  for (let key in value) {
    bodyFormData.append(key, value[key]);
  }
  return APIClient.post(`${"Button_Record"}`, bodyFormData);
}
