import React, { Component, Fragment } from "react";

import img1 from "../../../assets/img/Adresse(FAQ).png";
import img2 from "../../../assets/img/Öffnungszeiten(FAQ).png";
import img3 from "../../../assets/img/Kontakt(FAQ).png";

export default class gride extends Component {
  state = {
    show: false,
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="main1-uber flex-j-c w-100">
            <div className="main1-uber-dad flex-col w-90">
              <div className="main1-FAQ-dad-top t-c">Über uns</div>
              <div className="main1-FAQ-dad-top2"> </div>
              <div className="main1-uber-dad-down flex w-100">
                <div className="main1-uber-dad-down-left">
                  <div className="main1-uber-dad-down-left1">
                    <span className="main1-uber-dad-down-left1-spn1">
                      TELE
                      <span className="main1-uber-dad-down-left1-spn2">
                        print
                      </span>
                    </span>
                    – mit 25 Jahren Erfahrung und … weil Qualität kein
                    Zufallsprodukt ist!
                  </div>
                  <div className="main1-uber-dad-down-left2"></div>
                  <div className="main1-uber-dad-down-left4">
                    Alles begann im Oktober 1995 mit vier gebrauchten Kopierern
                    und der Idee, die Möglichkeiten der Telekommunikation mehr
                    und mehr zu nutzen – die TELEKOPIE war als Einzelunternehmen
                    auf etwa 75m2 Raum geboren. In kürzester Zeit ist daraus ein
                    Treffpunkt für zahlreiche KünstlerInnen, Studierende,
                    GrafikerInnen aber auch für kleine und große Firmen und KMUs
                    geworden.
                  </div>
                  <div className="main1-uber-dad-down-left4">
                    Wie alle anderen Unternehmen hat auch die TELEKOPIE Auf und
                    Abs erlebt, aber interessant war dabei immer das Wachstum
                    des Unternehmens. Unsere Angebotspalette wurde laufend
                    erweitert, neue und größere Maschinen wurden angeschafft und
                    die Produktion beschleunigt. Dies und die stetig wachsende
                    Mitarbeiterzahl machten es notwendig, in größere
                    Geschäftsräume zu übersiedeln. So wie wir dem 7. Bezirk
                    (unserem „Grätzl“) treu blieben, waren es auch unsere
                    Stammkunden.
                  </div>
                  <div className="main1-uber-dad-down-left4">
                    Im Jahre 2011 war es soweit, aufgrund stürmischer Zeiten mit
                    vielen Turbulenzen musste die TELEKOPIE® aufhören. Die
                    MitarbeiterInnen haben die Firma übernommen und daraus die
                    zeitgemäßere und modernere TELEprint gemacht.
                  </div>
                  <div className="main1-uber-dad-down-left4">
                    Die TELEprint KG war als Mitarbeiterbeteiligungsfirma
                    geboren. Der größte Teil unserer Kunden hielt uns die Treue.
                    Der Name hatte sich geändert, unser Motto blieb gleich: Wir
                    produzieren alles, was legal ist. Soll heißen: Auch
                    Sonderwünsche jeglicher Art werden bei uns nach Möglichkeit
                    verwirklicht. Und das wie seit jeher mit Freude und ????.
                  </div>
                  <div className="main1-uber-dad-down-left4">
                    Mittlerweile verfügen wir über ca. 300 m2 und sind ein
                    multikulturelles Team aus 5 Personen. Wir können unseren
                    Kunden, die uns zum Teil seit fast 20 Jahren treu sind,
                    alles aus einer Hand anbieten, was uns tagtäglich viel
                    Freude bereitet und worauf wir auch sehr stolz sind.
                  </div>
                </div>
                <div className="main1-uber-dad-down-right">
                  <div className="main1-uber-dad-down-right1 flex-j-s wrap">
                    <div className="main1-uber-dad-down-right1-1 m-b-20">
                      <div className="main1-FAQ-dad-middle1-img cursor-p">
                        <img src={img1} alt="" />
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                        Adresse
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                        <a
                          href="https://g.page/Teleprint-digitaldruck?share"
                          className="main1-FAQ-dad-middle1-txt2a"
                        >
                          TELEprint Digitaldruck KG
                        </a>
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt3">
                        Westbahnstraße 9, 1070 Wien
                      </div>
                    </div>
                    <div className="main1-uber-dad-down-right1-1 m-b-20">
                      <div className="main1-FAQ-dad-middle1-img cursor-p">
                        <img src={img2} alt="" />
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                        Öffnungszeiten
                      </div>
                      <div
                        className="main1-FAQ-dad-middle1-txt3"
                        id="main1-FAQ-dad-middle1-txt3"
                      >
                        Montag bis Freitag
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt3">
                        09:00 – 18:00 Uhr
                      </div>
                    </div>
                    <div className="main1-uber-dad-down-right1-1 m-b-20">
                      <div className="main1-FAQ-dad-middle1-img cursor-p">
                        <img src={img3} alt="" />
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                        Kontakt
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                        <a
                          href="tel:++43 1 524 32 56"
                          className="main1-FAQ-dad-middle1-txt2a"
                        >
                          +43 1 524 32 56
                        </a>
                      </div>
                      <div>
                        <a
                          href="mailto:print@teleprint.at"
                          className="main1-FAQ-dad-middle1-txt2a"
                        >
                          print@teleprint.at
                        </a>
                      </div>
                    </div>
                    {/* <div className="main1-uber-dad-down-right1-1 m-b-20">
                      <div className="main1-FAQ-dad-middle1-img cursor-p">
                        <img src={img4} alt="" />
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                        Adresse
                      </div>
                      <div className="main1-FAQ-dad-middle1-txt2 m-0-a w-90 cursor-p">
                        <a
                          href="https://teleprint.at/anfrage/"
                          className="main1-FAQ-dad-middle1-txt2a"
                        >
                          Gerne beantworten wir Ihre Anfragen.
                        </a>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <iframe
                      title="map"
                      className="main1-uber-map"
                      frameborder="0"
                      allowfullscreen
                      aria-hidden="false"
                      tabindex="0"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2659.3566802409036!2d16.347488917709263!3d48.19974534843316
                                !3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x254c06a2d168d2fb!2sTELEprint%20Digitaldruck%20KG!5e0!3m2!1sde!2sat!4v15973
                                18064795!5m2!1sde!2sat"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
