import React, { Component } from "react";
// api
// import { uiPosition } from "../../../api/Api";

// images
import img1 from "../../../assets/img/folder.png";
import img2 from "../../../assets/img/flyer.png";

import img6 from "../../../assets/img/Folder-Wickelfalz.png";
import img7 from "../../../assets/img/folder8.jpg";
import img8 from "../../../assets/img/folderz8.jpg";
import img9 from "../../../assets/img/folderz6.jpg";
import img10 from "../../../assets/img/rullup.jpg";
import img11 from "../../../assets/img/Lesezeichen.jpg";
import img12 from "../../../assets/img/ButtonB.jpg";

import { NavLink } from "react-router-dom";

export default class index extends Component {
  state = { uiPosition: [] };

  componentDidMount() {
    this.getUiPosition();
  }

  getUiPosition = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <main>
        <div class="main1-home w-100 flex-j-c">
          <div class="main1-home-dad w-90 flex-j-s">
            <div class="main1-home-txt1">
              <div>Die Online-Digitaldruckerei im Herzen von Wien!</div>
            </div>
            {/* <div class="main1-home-txts">
              <div class="main1-home-txt2">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://maps.google.com/?q=Westbahnstraße 9, 1070 Wien"
                >
                  Westbahnstraße 9, 1070 Wien{" "}
                  <i class="mdi mdi-map-marker head1-icn1"></i>
                </a>
              </div>
              <div class="main1-home-txt2">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="mailto:print@teleprint.at"
                >
                  Print@TelePrint.at{" "}
                  <i class="mdi mdi-email-outline head1-icn1"></i>
                </a>
              </div>
              <div class="main1-home-txt2">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="tel:+43 1 524 32 56"
                >
                  +43 1 524 32 56 <i class="mdi mdi-phone head1-icn1"></i>
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div class="main2-home w-100 flex-j-c">
          <div class="main2-home-dad w-90 flex-j-s wrap">
            <div class="main2-home-box1">
              <NavLink to="product/FLYER">
                <img src={img2} alt="" />
                <div class="main2-home-txt1">FLYER</div>
                <div class="main2-home-txt2">
                  <a href="product/FLYER">Flyer</a>
                </div>
              </NavLink>
            </div>
            <div class="main2-home-box1">
              <NavLink to="product/Folder">
                <img src={img1} alt="" />
                <div class="main2-home-txt1">Folder</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Einfachfalz
                  </a>
                </div>
              </NavLink>
            </div>
            <div class="main2-home-box1">
              <NavLink to="product/FolderWickelfalz6seiten">
                <img src={img6} alt="" />
                <div class="main2-home-txt1">Folder</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Wickel falz 6seiten
                  </a>
                </div>
              </NavLink>
            </div>

            <div class="main2-home-box1">
              <NavLink to="product/FolderWickelfalz8seiten">
                <img src={img7} alt="" />
                <div class="main2-home-txt1">Folder</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Wickel falz 8seiten
                  </a>
                </div>
              </NavLink>
            </div>
            <div class="main2-home-box1">
              <NavLink to="product/FolderZfalz6Seiten">
                <img src={img9} alt="" />
                <div class="main2-home-txt1">Folder</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Zfalz 6Seiten
                  </a>
                </div>
              </NavLink>
            </div>
            <div class="main2-home-box1">
              <NavLink to="product/FolderZfalz8Seiten">
                <img src={img8} alt="" />
                <div class="main2-home-txt1">Folder</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Zfalz 8Seiten
                  </a>
                </div>
              </NavLink>
            </div>

            <div class="main2-home-box1">
              <NavLink to="product/rollup">
                <img src={img10} alt="" />
                <div class="main2-home-txt1">Roll-Up</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/klapp-visitenkarten/">
                    {/* Klapp Visitenkarten */}Roll-Up
                  </a>
                </div>
              </NavLink>
            </div>
            <div class="main2-home-box1">
              <NavLink to="product/Lesezeichen">
                <img src={img11} alt="" />
                <div class="main2-home-txt1">Lesezeichen</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Lesezeichen
                  </a>
                </div>
              </NavLink>
            </div>
            <div class="main2-home-box1">
              <NavLink to="product/Button">
                <img src={img12} alt="" />
                <div class="main2-home-txt1">Button</div>
                <div class="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Button
                  </a>
                </div>
              </NavLink>
            </div>
            {/* <div class="main2-home-box1">
              <img src={img5} alt="" />
              <div class="main2-home-txt1">BUCHDRUCK</div>
              <div class="main2-home-txt2">
                <a href="https://teleprint.at/produkte/softcover-buecher/">
                  Softcover-Bücher
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div class="main3-home w-100 flex-j-c">
          <div class="main3-home-dad w-80">
            <h1 class="main3-child1">Teleprint</h1>
            <div class="main3-child1-txts">
              <div class="main3-child1-txt-1">
                Die Online-Digitaldruckerei im Herzen von Wien !
              </div>
              {/* <div class="main3-child1-txt-1">im Herzen von Wien</div> */}
              <div class="main3-child1-txt2">
                Wir begleiten Sie gerne mit unseren langjährigen Erfahrungen bei
                der Verwirklichung Ihrer Druckvorhaben!
                <br />
                Teleprint produziert auf modernsten, erstklassigen
                Druckmaschinen und zwar ausschließlich in Wien.
              </div>
              <div class="main3-child1-txt3">
                Sie können uns CO
                <sub>2</sub>
                <nbsp></nbsp> frei mit dem Fahrrad oder mit den Öffis erreichen.
                {/* <br />
                Kurze Transportwege garantieren eine umweltfreundlichere
                Produktion. */}
              </div>
              <div class="main3-child1-btn">
                <button>Druckprodukte von A-Z</button>
              </div>
            </div>
          </div>
        </div>
        <div class="main4-home flex-j-c w-100">
          <div class="main4-home-dad w-90 flex-j-c">
            <div class="main4-home-left-txt1">
              <h2>Nicht das passende Produkt gefunden?</h2>
              <h3>Wenden sie sich an unseren Kundenservice!</h3>
              <div class="main4-home-left-boxs flex">
                <div class="main4-home-left-box-1">
                  <a href="/anfrage/" target="_self">
                    {/* <i class="mdi mdi-plus"></i> */}
                    <span>ANFRAGE</span>
                  </a>
                </div>
                <div class="main4-home-left-box-2">
                  <a href="tel:+4315243256">
                    <i class="mdi mdi-phone"></i>
                    <span>01 5243 256</span>
                  </a>
                </div>
                <div class="main4-home-left-box-3">
                  <a href="mailto:print@teleprint.at">
                    <i class="mdi mdi-email"></i>
                    <span>print@teleprint.at</span>
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="main4-home-child1-right">
              <img src={img6} alt="" />
            </div> */}
          </div>
        </div>
        {/* <div class="main5-home w-100 flex-j-c">
          <div class="main5-home-dad w-90 flex-j-s">
            <div class="main5-home-child1-res w-50">
              <div class="main5-icns">
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
              </div>
              <div>
                <i class="main5-txt1">
                  Wirklich ganz tolle Firma prima Leute haben meine Aufträge zur
                  vollsten Zufriedenheit prompt erledigt Preis Leistung Super !
                </i>
              </div>
              <div class="main5-txt2">
                <strong class="">Michael Hollan</strong>
                <span class="testimonial-name-divider"> / </span>
                <span class="testimonial-company test_company">Customer</span>
              </div>
            </div>
            <div class="main5-home-child2-res d-l w-50">
              <div class="main5-icns">
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
                <i class="mdi mdi-star"></i>
              </div>
              <div>
                <i class="main5-txt1">
                  Ist wärmstens zu Empfehlen. Es gibt fast nichts was es nicht
                  gäbe in Digital zu lösen und umzusetzen zu vervielfältigen ,
                  Drucken und Bearbeiten und Projizieren. Dafür ist der Chef
                  (Sehr Flexibel und Fleißig) persönlich und seine Mitarbeiter
                  auf das beste vorbereitet und Eingespielt…
                </i>
              </div>
              <div class="main5-txt2">
                <strong class="">Christian Penn</strong>
                <span class="testimonial-name-divider"> / </span>
                <span class="testimonial-company test_company">
                  Local Guide
                </span>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    );
  }
}
