import React, { Component, Fragment } from "react";

//redux
import { connect } from "react-redux";
import store from "../../../../store/store";
import {
  removeCart,
  // getCart,
  // updateCart,
  addCart,
  reduce,
} from "../../../../action";

// import Heart from "../../../../assets/svg/heart";
// import Share from "../../../../assets/svg/share";

// API
import { address } from "../../../../api/Api";

// Components

import AddAddress from "../../../../components/address";

class Cart1 extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
    address: [],
  };

  componentDidMount() {
    //hello
    this.unSub = store.subscribe(this.sub);
    this.getAddress();
  }

  getAddress = async () => {
    const data = await address.getAll();

    if (data.status === 204) {
      this.setState({ address: [] });
    } else {
      this.setState({ address: data.data.data });
      await this.setState((state) => ({
        ...state,
        destinationAddressId: data.data.data[0].id,
        settings: data.data.data.map((item) =>
          item.id === data.data.data[0].id
            ? { ...item, open: !item.open }
            : { ...item, open: false }
        ),
      }));
    }
  };

  unSub;
  sub = async () => {
    let reduxCart = store.getState();
    this.setState({ reduxCart });
  };

  componentWillUnmount() {
    this.unSub();
  }

  close = () => {
    this.setState({
      show: false,
    });
  };
  open = () => {
    this.setState({
      show: true,
    });
  };

  removeAddress = async (id) => {
    try {
      // const data =
      await address.deleteAddress(id);
      this.props.openNotif("success", "تایید", "با موفقیت حذف گردید");
      this.getAddress();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <Fragment>
        <AddAddress
          openNotif={this.props.openNotif}
          open={this.open}
          close={this.close}
          show={this.state.show}
          getAddress={this.getAddress}
        />
        <div class="mcart1-r-2 flex-j-c">
          <div class="mc1ur">
            <h3 style={{ textAlign: "right" }}>آدرس ها</h3>

            {this.state.address.map((x) => {
              return (
                <div class="mcart2-1">
                  <div class="flex-j-s">
                    <div class="circlect2"></div>
                    <div>
                      <div class="txtct2-2">
                        <span>{x.name}</span>: نام انتخابی
                      </div>
                      <div class="txtct3-3">{x.description}</div>
                    </div>
                  </div>
                  <div>
                    <div class="mcart2-2 flex-j-s">
                      <div class="flex-j-s">
                        <div
                          onClick={() => this.removeAddress(x.id)}
                          class="txtad-2 cursorP"
                        >
                          حذف آدرس <i class="mdi mdi-delete-outline"></i>{" "}
                        </div>
                        {/* <div class="txtad-1">
                        ویرایش آدرس <i class="mdi mdi-pencil-outline"></i>
                      </div> */}
                      </div>
                      <div class="txtct2-4">
                        کد پستی: <span> {x.postalCode}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div class="mcart2-button">
              <div class="flex-j-s">
                <div class="butcrt2">
                  <button onClick={this.open} type="submit">
                    افزودن آدرس جدید <i class="mdi mdi-map-marker-outline"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  AddCart: function (value, price) {
    return dispatch(addCart(value, price));
  },
  RemoveCart: function (value) {
    return dispatch(removeCart(value));
  },
  Reduce: function (value) {
    return dispatch(reduce(value));
  },
  // UpdateCart: function (value) {
  //   return dispatch(updateCart(value));
  // },
});

export default connect(mapStatetoProps, mapDispatchToProps)(Cart1);
