import React, { Component } from "react";
import { Notification } from "rsuite";
import { account } from "../../../../api/Api";
import img11 from "../../../../assets/images/Vector (2).png";
import { NavLink } from "react-router-dom";

export default class StepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        phoneNumber: "",
        verifyCode: "",
      },
      formError: {},
      redirect: false,
      expireInSeconds: "",
    };
  }

  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.setState({ redirect: true });
    }
  }

  handleChange = (e) => {
    this.setState({
      formValue: { ...this.state.formValue, phoneNumber: e.target.value },
    });
  };

  handleSubmit = async () => {
    try {
      const data = await account.checkMobile(this.state.formValue);
      if (data.data.status === 200) {
        this.props.loginData(
          this.state.formValue,
          data.data.data.expireInSeconds
        );
      } else {
        this.props.loginData(this.state.formValue);
      }
      this.open("success", "تایید", data.data.message);
      this.props.goNext("step2");
    } catch (e) {
      console.error(e);
      this.open("error", "خطا", "کد تایید به شماره موبایلتان ارسال نشد");
    }
  };
  render() {
    return (
      <div class="headmo flex-j-c w-100">
        <div class="headmo-b flex-j-c flexd">
          <div class="hmo1 flex-j-c">
            <div class="hmo2 flex-j-c">
              <div class="hmo3 flex-j-s">
                <div>
                  <div class="txt1m">پت شـاپ حـــامـــی </div>
                  <div class="txt2m">فروشگاه حیوانات خانگی </div>
                </div>
                <div class="moimg1">
                  <img alt="" src={img11} />
                </div>
              </div>
            </div>
          </div>
          <div class="hmo4">
            <h1>ورود به فروشگاه</h1>
            <h4>
              شماره همراه فعال خودتون رو وارد کنید
              <br /> تا کد تایید براتون ارسال بشه
            </h4>

            <div>
              <div class="txtm3">
                شماره همراه <i class="mdi mdi-cellphone"></i>
              </div>
              <div class="flexd">
                <input
                  onChange={this.handleChange}
                  class="putm"
                  placeholder="09109064472"
                />
                <button
                  onClick={this.handleSubmit}
                  type="submit"
                  class="buttmo"
                >
                  ارسال کد تایید
                </button>
                <button class="buttmo">
                  <NavLink to="/">بازگشت به فروشگاه</NavLink>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
