import React, { Component, Fragment } from "react";
import {
  Form,
  FormControl,
  Button,
  Modal,
  SelectPicker,
  ControlLabel,
  Schema,
  Loader,
} from "rsuite";

// API
import { address, zone } from "../../api/Api";

export default class LoginForm extends Component {
  state = {
    zone: [{ label: "", value: "" }],
    initialValue: {
      name: "",
      description: "",
      postalCode: "",
      latitude: "",
      zoneId: "",
      longitude: "",
    },
  };

  handleSubmit = async () => {
    try {
      let data = await address.create(this.state.initialValue);
      this.props.getAddress();
      if (data.status === 201) {
        this.props.openNotif("success", "موفقیت", "با موفقیت انجام شد");
        this.props.close();
      }
    } catch (error) {
      this.props.openNotif("error", "خطا", error);
      console.log(error);
    }
  };

  componentDidMount() {
    this.getAllZone();
  }

  getAllZone = async () => {
    try {
      const data = await zone.getAllZone();
      if (data.status === 200) {
        let newValue = [];
        data.data.data.forEach((e) => {
          newValue = [...newValue, { label: e.name, value: e.id }];
        });
        this.setState({ zone: newValue });
      }
    } catch (error) {}
  };

  handleChangeZone = async (value) => {
    await this.setState({
      values: { ...this.state.values, zoneId: value },
      initialValue: { ...this.state.initialValue, zoneId: value },
    });
  };

  onChange = async (name, value) => {
    await this.setState({
      initialValue: { ...this.state.initialValue, [name]: value },
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          overflow={false}
          size="md"
          backdrop={false}
          show={this.props.show}
          onHide={this.props.close}
        >
          <Modal.Header>
            <Modal.Title>hallo julia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              style={{ direction: "rtl" }}
              fluid
              formDefaultValue={this.state.initialValue}
            >
              <ControlLabel>منطقه</ControlLabel>
              <SelectPicker
                onChange={this.handleChangeZone}
                data={this.state.zone}
                block
                style={{ marginBottom: "20px" }}
              />
              <ControlLabel>نام انتخابی</ControlLabel>
              <FormControl
                style={{ marginBottom: "20px" }}
                className="w-100I"
                name="name"
                block
                onChange={(e) => this.onChange("name", e)}
                placeholder=""
                value={this.state.initialValue && this.state.initialValue.name}
                type="text"
              />

              <ControlLabel>ادرس دقیق</ControlLabel>
              <FormControl
                className="w-100I"
                name="description"
                style={{ marginBottom: "20px" }}
                block
                onChange={(e) => this.onChange("description", e)}
                placeholder=""
                value={
                  this.state.initialValue && this.state.initialValue.description
                }
                type="text"
              />

              <ControlLabel>کد پستی</ControlLabel>
              <FormControl
                className="w-100I"
                name="postalCode"
                block
                onChange={(e) => this.onChange("postalCode", e)}
                placeholder=""
                value={
                  this.state.initialValue && this.state.initialValue.postalCode
                }
                type="number"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleSubmit} appearance="primary">
              Ok
            </Button>
            <Button onClick={this.props.close} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
