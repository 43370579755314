import { APIClient } from "./_api";

const endpoint = "/Customer";

function checkMobile(value) {
  return APIClient.post(`${endpoint}/checkMobile`, value);
}

function verify(value) {
  return APIClient.post(`${endpoint}/login`, value);
}

function giveStar(value) {
  return APIClient.post(`${endpoint}/point`, value);
}

function getMe() {
  return APIClient.get(`${endpoint}`);
}

function update(value) {
  return APIClient.put(`${endpoint}`, value);
}

export { checkMobile, verify, getMe, update, giveStar };
