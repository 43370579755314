import React, { Component, Fragment } from "react";

import notif from "../../../utils/notif";

//redux
import { connect } from "react-redux";
import { removeCart, addCart, reduce } from "../../../action";

import { setAuthToken } from "../../../api/_api";
import { account } from "../../../api/Api";
import { Rating } from "react-simple-star-rating";

//api
import { mentor } from "../../../api/Api";
class ProductDetails extends Component {
  state = {
    data: {
      city: "",
      description: "",
      expertise: "",
      firstName: "",
      history: "",
      lastName: "",
    },
    rating: 0,
  };

  componentDidMount() {
    this.getMentor();
  }

  getMentor = async () => {
    try {
      const id = this.props.props.match.params.id;
      const { data } = await mentor.getById(id);
      this.setState({ data: data.data });
    } catch (error) {
      console.error(error);
    }
  };

  handleRating = async (rating) => {
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        notif(" برای امتیاز دادن لطفا ابتدا از قسمت ورود وارد شوید");
      } else {
        this.setState({ rating });
        setAuthToken(token);
        let newData = {
          mentorId: this.props.props.match.params.id,
          point: rating,
        };
        await account.giveStar(newData);
        notif(" امتیاز شما با موفقیت ثبت شد");
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { data } = this.state;
    return (
      <Fragment>
        <main>
          {data && (
            <div class="coach-main1 w-100 flex-j-c">
              <div class="coach-main1-baba flex-j-c">
                <div class="flex-j-s flexd">
                  <div class="coach-img-1 flex-j-c">
                    <img
                      alt=""
                      src={data.images && data.images[0] && data.images[0].url}
                    />
                  </div>
                  <div>
                    <Rating
                      onClick={this.handleRating}
                      ratingValue={this.state.rating} /* Rating Props */
                    />
                  </div>
                  <div class="coach-main2">
                    <div class="coach-main2-txt1 flex-j-s">
                      <div class="coach-main2-txt1-child">
                        {data.firstName + " " + data.lastName}
                      </div>
                      <div class="coach-main2-txt1-child">
                        نام و نام خانوادگی
                      </div>
                    </div>
                    <div class="coach-main2-txt2 flex-j-s">
                      <div class="coach-main2-txt1-child">
                        {data.expertise && data.expertise}
                      </div>
                      <div class="coach-main2-txt1-child">رشته فعالیت</div>
                    </div>
                    <div class="coach-main2-txt1 flex-j-s">
                      <div class="coach-main2-txt1-child">
                        {data.history && data.history}
                      </div>
                      <div class="coach-main2-txt1-child">سابقه فعالیت</div>
                    </div>
                    <div class="coach-main2-txt2 flex-j-s">
                      <div class="coach-main2-txt1-child">
                        {data.city && data.city}
                      </div>
                      <div class="coach-main2-txt1-child">
                        شهرهای محل فعالیت
                      </div>
                    </div>
                    <div class="coach-main2-txt3">
                      <div class="coach-main2-txt1-child">توضیحات </div>
                      <div class="coach-main2-txt3">
                        <div class="coach-main2-txt1-child-2">
                          {data.description && data.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </Fragment>
    );
  }
}

function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  AddCart: function (value, price) {
    return dispatch(addCart(value, price));
  },
  RemoveCart: function (value) {
    return dispatch(removeCart(value));
  },
  Reduce: function (value) {
    return dispatch(reduce(value));
  },
  // UpdateCart: function (value) {
  //   return dispatch(updateCart(value));
  // },
});

export default connect(mapStatetoProps, mapDispatchToProps)(ProductDetails);
