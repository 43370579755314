import React, { Component, Fragment } from "react";

export default class gride extends Component {
  state = {
    show: false,
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="main1-anfrage w-100 flex-j-c">
            <div className="main1-anfrage-dad w-90">
              <div className="main1-anfrage-child1">
                <div className="main1-anfrage-child1-txt1">Anfrage</div>
                <span></span>
                <div className="main1-anfrage-child1-txt2">
                  {/* „<span className="">*</span>“  */}
                  {/* zeigt erforderliche Felder an */}
                  Pflichtfelder
                </div>
                <div className="main1-anfrage-child1-txt3">
                  Ihre Kontaktdaten
                </div>
              </div>
            </div>
          </div>
          <div className="main2-anfrage w-100 flex-j-c">
            <div className="main2-anfrage-dad w-90">
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Vorname</div>
                <div className="main2-anfrage-put">
                  <input type="text" />
                </div>
                <div className="main2-anfrage-txt1 m-t-20">
                  Email
                  <span>*</span>
                </div>
                <div className="main2-anfrage-put">
                  <input type="text" />
                </div>
                <div className="main1-anfrage-child1-txt3">Produktauswahl</div>
              </div>
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Nachname</div>
                <div className="main2-anfrage-put2">
                  <input type="text" />
                </div>
                <div className="main2-anfrage-txt1 m-t-20">Telefonnummer</div>
                <div className="main2-anfrage-put2">
                  <input type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="main2-anfrage w-100 flex-j-c">
            <div className="main2-anfrage-dad w-90">
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Produkt</div>
                <div className="main2-anfrage-put">
                  <select className="main2-anfrage-select">
                    <option
                      value=""
                      selected="selected"
                      className="gf_placeholder"
                    >
                      Bitte wählen
                    </option>
                    <option value="Aktenvernichten">Aktenvernichten</option>
                    <option value="Aufkleber">Aufkleber</option>
                    <option value="Backlight">Backlight</option>
                    <option value="Banner">Banner</option>
                    <option value="Blöcke">Blöcke</option>
                    <option value="Briefpapier">Briefpapier</option>
                    <option value="Broschüren">Broschüren</option>
                    <option value="Buttons">Buttons</option>
                    <option value="Bücher">Bücher</option>
                    <option value="Canvas">Canvas</option>
                    <option value="Displays">Displays</option>
                    <option value="Durchschreibegarnituren">
                      Durchschreibegarnituren
                    </option>
                    <option value="Etiketten">Etiketten</option>
                    <option value="Event-Set">Event-Set</option>
                    <option value="Flyer">Flyer</option>
                    <option value="Folder/Faltprospekte">
                      Folder/Faltprospekte
                    </option>
                    <option value="Grußkarten">Grußkarten</option>
                    <option value="Hinweistafeln">Hinweistafeln</option>
                    <option value="Kalender">Kalender</option>
                    <option value="Kapaplaten">Kapaplaten</option>
                    <option value="Keilrahmen">Keilrahmen</option>
                    <option value="Klebefolien">Klebefolien</option>
                    <option value="Kugelschreiber">Kugelschreiber</option>
                    <option value="Kuverts">Kuverts</option>
                    <option value="Kühlschrankmagnete">
                      Kühlschrankmagnete
                    </option>
                    <option value="Lesezeichen">Lesezeichen</option>
                    <option value="Lichtwerbung">Lichtwerbung</option>
                    <option value="Mousepads">Mousepads</option>
                    <option value="Notizblöcke">Notizblöcke</option>
                    <option value="Papierverkauf">Papierverkauf</option>
                    <option value="Plakate">Plakate</option>
                    <option value="Polster">Polster</option>
                    <option value="Poster">Poster</option>
                    <option value="Postkarten">Postkarten</option>
                    <option value="Rill &amp; Stanzprodukte">
                      Rill &amp; Stanzprodukte
                    </option>
                    <option value="Roll Ups">Roll Ups</option>
                    <option value="Schaumplatten">Schaumplatten</option>
                    <option value="Schreddern">Schreddern</option>
                    <option value="Spiralblöcke">Spiralblöcke</option>
                    <option value="Spiralmappen">Spiralmappen</option>
                    <option value="Stempel">Stempel</option>
                    <option value="Sticker">Sticker</option>
                    <option value="Stofftaschen">Stofftaschen</option>
                    <option value="T-Shirts">T-Shirts</option>
                    <option value="Tapeten">Tapeten</option>
                    <option value="Tassen">Tassen</option>
                    <option value="Textildrucke">Textildrucke</option>
                    <option value="Tischaufsteller">Tischaufsteller</option>
                    <option value="Tischdecken">Tischdecken</option>
                    <option value="Tischschilder">Tischschilder</option>
                    <option value="Tischsets">Tischsets</option>
                    <option value="Transparente">Transparente</option>
                    <option value="Trennblätter">Trennblätter</option>
                    <option value="Türhänger">Türhänger</option>
                    <option value="Verkaufsständer">Verkaufsständer</option>
                    <option value="Visitenkarten">Visitenkarten</option>
                    <option value="Zeitungen">Zeitungen</option>
                    <option value="Sonstiges">Sonstiges</option>
                  </select>
                </div>
              </div>
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Anzahl</div>
                <div className="main2-anfrage-put2">
                  <input type="number" />
                </div>
                {/* <div className="main2-anfrage-txt2">
                  Bitte gib eine Zahl größer oder gleich
                  <strong>0</strong>
                  ein.
                </div> */}
              </div>
            </div>
          </div>
          <div className="main2-anfrage w-100 flex-j-c">
            <div
              className="main2-anfrage-dad w-90"
              id="main2-anfrage-dad-no-brdr"
            >
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">
                  Beschreibung
                  <span>*</span>
                </div>
                <textarea
                  className="main2-anfrage-txtarea"
                  rows="10"
                  cols="50"
                ></textarea>
              </div>
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Datei(en) hochladen</div>
                <div className="main2-anfrage-box1 t-c">
                  <div className="main2-anfrage-txt3">
                    Ziehe Dateien hier her oder
                  </div>
                  <div className="main2-anfrage-btn1 t-c">
                    <button>Wähle Dateien aus</button>
                  </div>
                </div>
                <div className="main2-anfrage-txt3">
                  Akzeptierte Dateiformat: pdf, eps, jpg, png, <br />
                  Maximale Dateigröße: 256 MB. <br />
                  Maximale Dateianzahl 10.
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-100 flex-j-c">
            <div className="w-90 p-b-50">
              <div className="main2-anfrage-txt1">CAPTCHA</div>
              <div className="main2-anfrage-logo flex-j-s">
                <div className="flex w-60 m-t-24">
                  <div className="main2-anfrage-logo-child1"></div>
                  <div className="main2-anfrage-logo-child2">
                    ich bin kein Roboter
                  </div>
                </div>
                <div className="main2-anfrage-right d-r">
                  <div className="main2-anfrage-right-img">
                    <img src="./img/blue-logo.png" alt="" />
                  </div>
                  <div className="main2-anfrage-right-txt1">reCAPTCHA</div>
                  <div className="main2-anfrage-right-txt2">
                    Privacy - Voorwaarden
                  </div>
                </div>
              </div>
              <div className="main2-anfrage-btn1 m-t-20">
                <button>ABSENDEN</button>
              </div>
            </div>
          </div> */}
        </main>
      </Fragment>
    );
  }
}
