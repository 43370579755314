import React, { Component } from "react";

// import img2 from "../../../assets/images/Vector.png";

export default class index extends Component {
  render() {
    return (
      <footer>
        <div className="footer1 flex-j-c w-100">
          <div className=" footer1-dad flex-j-s w-90">
            <div className="footer1-dad-left flex-col ">
              <div className="footer1-dad-left-img">
                <img src="./img/TelePrint-Logo.svg" alt="" />
              </div>
              {/* <div className="footer1-dad-left-txt">
                <div>Teleprint steht für </div>
                <div>Qualität und </div>
                <div>Schnelligkeit</div>  
              </div> */}
            </div>
            <div className=" footer1-dad-middle1 flex-col position-r">
              <div id="icon-border"></div>
              <div id="icon-border2"></div>
              <div className="footer1-dad-middle1-1"> Kontakt </div>
              <div className="footer1-dad-middle1-2"> </div>
              <div className="footer1-dad-middle1-3">
                <ul className="footer1-dad-middle1-3-ul">
                  <li>
                    <a
                      className="color-a"
                      href="https://maps.google.com/?q=Westbahnstraße 9, 1070 Wien"
                    >
                      <i className="mdi mdi-map-marker"></i>
                      Westbahn Str. 9, 1070 Wien
                    </a>
                  </li>
                  <li className="">
                    <a className="color-a" href="mailto:print@teleprint.at">
                      <i className=" mdi mdi-email-outline "></i>
                      print@teleprint.at
                    </a>
                  </li>
                  <li>
                    <a className="color-a" href="tel:+43 1 524 32 56">
                      <i className="mdi mdi-phone"></i>
                      +43 1 524 32 56
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" footer1-dad-middle2">
              <div className="footer1-dad-middle1-1"> Informationen </div>
              <div className="footer1-dad-middle1-2"> </div>
              <div>
                <ul className="footer1-dad-middle2-3-ul">
                  <li>
                    <a
                      className="color-a2"
                      href="https://teleprint.at/haeufige-fragen-faq/"
                    >
                      Häufige Fragen (FAQ)
                    </a>
                  </li>
                  <li>
                    <a
                      className="color-a2"
                      href="https://teleprint.at/ueber-uns/"
                    >
                      Über uns
                    </a>
                  </li>
                  <li>
                    <a
                      className="color-a2"
                      href="https://teleprint.at/kontakt/"
                    >
                      Kontakt
                    </a>
                  </li>
                  <li id="middle2-3">
                    <a
                      className="color-a2"
                      href="https://teleprint.at/impressum/"
                    >
                      Impressum
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" footer1-dad-middle2">
              <div className="footer1-dad-middle1-1"> Links </div>
              <div className="footer1-dad-middle1-2"> </div>
              <div>
                <ul className="footer1-dad-middle2-3-ul">
                  <li>
                    <a
                      className="color-a2"
                      href="https://teleprint.at/produktuebersicht/"
                    >
                      Produkte
                    </a>
                  </li>
                  <li id="middle2-3">
                    <a
                      className="color-a2"
                      href="https://teleprint.at/produktuebersicht/"
                    >
                      Anfrage
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer2 flex-j-c w-100">
          <div className="footer2-dad w-90 d-r">
            <div className="footer2-dad-right flex">
              <div className="footer2-dad-right1"></div>
              <div className="footer2-dad-right1"></div>
              <div className="footer2-dad-right1"></div>
            </div>
            <div>
              <a className="color-a2" href="https://teleprint.at/">
                !Teleprint
              </a>
              © 2021 erstellt von <nbsp />
              <a className="color-a2" href="https://ishtar.at/">
                Ishtar <nbsp />
              </a>
              Multiservice und Handels GmbH. Alle Rechte vorbehalten
            </div>
          </div>
        </div>
        <a href="#top" className="back-to-top position-r">
          <i className="back-to-top-i mdi mdi-chevron-up"></i>
        </a>
      </footer>
    );
  }
}
