import React, { Component } from "react";
import { Fragment } from "react";
// import { NavLink } from "react-router-dom";
// api

import { Redirect } from "react-router-dom";
import { Notification } from "rsuite";

import Dashboard from "../components/dashboard";
import Orders from "../components/orders";
import Profile from "../components/profile";
import Favarits from "../components/favarits";
import Address from "../components/address";
import ThisOrder from "../components/thisOrder";
//api
import { invoice, account } from "../../../api/Api";
export default class index extends Component {
  state = {
    lastReport: [],
    allReport: 0,
    shipmentData: [],
    resultData: [],
    page: "dashboard",
    orderId: "",
    paymentPending: [],
    submitted: [],
    ordersForShow: [],
    user: {
      phoneNumber: "",
      lastName: "",
      firstName: "",
    },
  };

  componentDidMount() {
    this.getInvoice();
    this.getMe();
  }

  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  showOrders = (ordersForShow) => {
    this.setState({ ordersForShow });
  };

  getMe = async () => {
    try {
      const data = await account.getMe();
      await this.setState({
        user: {
          ...this.state.user,
          phoneNumber: data.data.data.phoneNumber,
          lastName: data.data.data.lastName,
          firstName: data.data.data.firstName,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  mergeArrays = (dataaa = [], invoices = []) => {
    let res = [];
    res = dataaa.map((obj) => {
      const index = invoices.findIndex(
        (el) => String(el["id"]) === String(obj["invoiceId"])
      );
      const finalPrice = index !== -1 ? invoices[index].finalPrice : {};
      const orderId = index !== -1 ? invoices[index].orderId : {};
      return {
        ...obj,
        finalPrice,
        orderId,
      };
    });
    return res;
  };
  getInvoice = async () => {
    try {
      const { data } = await invoice.getInvoices();
      await this.setState({
        allReport: data.data.length,
      });
      let invoices = [...data.data];

      data.data.forEach(async (element) => {
        delete element["status"];
        const shipmentData = await invoice.getShipment(element.id);
        if (shipmentData.status !== 204) {
          await this.setState({
            shipmentData: [...this.state.shipmentData, shipmentData.data.data],
          });

          // console.log("this.state.shipmentData", this.state.shipmentData);
          // console.log("invoices", invoices);

          let resultData = this.mergeArrays(this.state.shipmentData, invoices);
          let paymentPending = resultData.filter((x) => {
            return x.status.name === "PAYMENT_PENDING";
          });
          let submitted = resultData.filter((x) => {
            return x.status.name === "SUBMITTED";
          });
          this.setState({
            resultData,
            lastReport: resultData.slice(0, 5),
            paymentPending,
            submitted,
            ordersForShow: resultData,
          });
        }
      });
    } catch (error) {}
  };

  handlePage = (page, orderId) => {
    this.setState({ page });
    if (orderId) {
      this.setState({ orderId });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/`} />;
    }
    return (
      <Fragment>
        <main>
          <div class="m5 flex-j-c w-100">
            <div class="m5-b flex-j-s w1-b">
              {/* //main */}
              {this.state.page === "dashboard" ? (
                <Dashboard
                  lastReport={this.state.lastReport}
                  allReport={this.state.allReport}
                  handlePage={this.handlePage}
                  paymentPending={this.state.paymentPending}
                  resultData={this.state.resultData}
                  submitted={this.state.submitted}
                  showOrders={this.showOrders}
                />
              ) : this.state.page === "orders" ? (
                <Orders
                  lastReport={this.state.lastReport}
                  allReport={this.state.allReport}
                  handlePage={this.handlePage}
                  paymentPending={this.state.paymentPending}
                  resultData={this.state.resultData}
                  submitted={this.state.submitted}
                  ordersForShow={this.state.ordersForShow}
                />
              ) : this.state.page === "profile" ? (
                <Profile user={this.state.user} />
              ) : this.state.page === "favarits" ? (
                <Favarits />
              ) : this.state.page === "address" ? (
                <Address openNotif={this.open} />
              ) : this.state.page === "thisOrder" ? (
                <ThisOrder orderId={this.state.orderId} />
              ) : (
                ""
              )}

              {/* //sideBar */}
              <div class="m5-br d-r flex">
                <div class="m5-brt flex">
                  <div class="m5-brt2">
                    <p>
                      {this.state.user.firstName +
                        " " +
                        this.state.user.lastName}
                      <br />
                      <nbsp class="m5-brt2n">
                        <i class="mdi mdi-cellphone "></i>
                        {this.state.user.phoneNumber}
                      </nbsp>
                    </p>
                  </div>
                </div>
                <div class="m5-brb p-r">
                  <div
                    onClick={() => this.handlePage("dashboard")}
                    class="m5-brb1 flex-j-s "
                  >
                    <div class="flex ">
                      <div class="m5-brb1a">
                        <i class="mdi mdi-gauge"></i>
                      </div>
                      <div class="m5-brb1c"> داشبورد</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>

                  <div
                    onClick={() => this.handlePage("profile")}
                    class="m5-brb1 flex-j-s"
                  >
                    <div class="flex">
                      <div class="m5-brb2a">
                        <i class="mdi mdi-account-outline"></i>
                      </div>
                      <div class="m5-brb1c"> اطلاعات کاربری</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>
                  <div
                    onClick={() => this.handlePage("orders")}
                    class="m5-brb1 flex-j-s"
                  >
                    <div class="flex">
                      <div class="m5-brb3a">
                        <i class="mdi mdi-clipboard-text-outline "></i>
                      </div>
                      <div class="m5-brb1c"> سفارشات</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>

                  <div
                    onClick={() => this.handlePage("address")}
                    class="m5-brb1 flex-j-s"
                  >
                    <div class="flex">
                      <div class="m5-brb5a">
                        <i class="mdi mdi-map-marker-outline"></i>
                      </div>
                      <div class="m5-brb1c"> آدرس ها</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>

                  <div
                    onClick={() =>
                      this.open(
                        "success",
                        "در دست ساخت",
                        "این صفحه در حال اماده شدن میباشد"
                      )
                    }
                    class="m5-brb1 flex-j-s"
                  >
                    <div class="flex">
                      <div class="m5-brb6a">
                        <i class="mdi mdi-comment-multiple-outline"></i>
                      </div>
                      <div class="m5-brb1c"> نظرات</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      this.open(
                        "success",
                        "در دست ساخت",
                        "این صفحه در حال اماده شدن میباشد"
                      )
                    }
                    // onClick={() => this.handlePage("favarits")}
                    class="m5-brb1 flex-j-s"
                  >
                    <div class="flex">
                      <div class="m5-brb4a">
                        <i class="mdi mdi-heart-outline"></i>
                      </div>
                      <div class="m5-brb1c">علاقه مندی ها</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>

                  <div
                    onClick={() =>
                      this.open(
                        "success",
                        "در دست ساخت",
                        "این صفحه در حال اماده شدن میباشد"
                      )
                    }
                    class="m5-brb1 flex-j-s"
                  >
                    <div class="flex">
                      <div class="m5-brb7a">
                        <i class="mdi mdi-comment-outline"></i>
                      </div>
                      <div class="m5-brb1c"> پیام ها</div>
                    </div>
                    <div class="m5-brb1d flex">
                      <i class="mdi mdi-chevron-left"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
