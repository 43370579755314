import React, { Component, Fragment } from "react";
import {
  Modal,
  Button,
  Form,
  FormControl,
  ControlLabel,
  Uploader,
} from "rsuite";
import notif from "../../../../utils/notif";

import { UploaderImage, mentor } from "../../../../api/Api";

export default class gride extends Component {
  state = {
    initialValue: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      description: "",
      city: "",
      history: "",
      expertise: "",
    },
  };

  componentDidMount() {}

  onChange = async (name, value) => {
    await this.setState({
      initialValue: { ...this.state.initialValue, [name]: value },
    });
  };

  handleSubmit = async () => {
    try {
      let newValues = {
        ...this.state.initialValue,
        addImage: this.state.imagesId,
      };
      await mentor.sendInfo(newValues);
      notif(" اطلاعات شما با موفقیت ثبت شد");
      this.props.close();
    } catch (error) {
      notif(" اطلاعات شما با موفقیت ثبت نشد");
    }
  };

  handleChangeAlbum = async ({ target, ...e }) => {
    try {
      let myArray = [];

      for (const key in e) {
        myArray.push(e[key].blobFile);
      }

      const { data } = await UploaderImage.addImages(myArray);
      let imagesId = [];
      data.data.forEach((element) => {
        imagesId.push(element.id);
      });

      this.setState({ myArray, imagesId });
    } catch (error) {}
  };
  render() {
    return (
      <Fragment>
        <Modal
          overflow={false}
          //   size="md"
          backdrop={false}
          show={this.props.show}
          onHide={this.props.close}
        >
          <Modal.Header>
            <Modal.Title>ثبت نام مربیان</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              style={{ direction: "rtl" }}
              fluid
              formDefaultValue={this.state.initialValue}
            >
              <ControlLabel>نام </ControlLabel>
              <FormControl
                style={{ marginBottom: "20px" }}
                className="w-100I"
                name="firstName"
                block
                onChange={(e) => this.onChange("firstName", e)}
                placeholder=""
                requierd
                // value={this.state.initialValue && this.state.initialValue.firstName}
                type="text"
              />

              <ControlLabel>نام خانوادگی</ControlLabel>
              <FormControl
                className="w-100I"
                name="lastName"
                style={{ marginBottom: "20px" }}
                block
                onChange={(e) => this.onChange("lastName", e)}
                placeholder=""
                type="text"
              />

              <ControlLabel>شماره همراه</ControlLabel>
              <FormControl
                className="w-100I"
                name="phoneNumber"
                style={{ marginBottom: "20px" }}
                block
                onChange={(e) => this.onChange("phoneNumber", e)}
                placeholder=""
                type="number"
              />

              <ControlLabel>توضیحات</ControlLabel>
              <FormControl
                className="w-100I"
                name="description"
                block
                onChange={(e) => this.onChange("description", e)}
                placeholder=""
                type="text"
              />

              <ControlLabel>شهر های خدمت</ControlLabel>
              <FormControl
                className="w-100I"
                name="city"
                block
                onChange={(e) => this.onChange("city", e)}
                placeholder=""
                type="text"
              />
              <ControlLabel>سابقه فعالیت</ControlLabel>
              <FormControl
                className="w-100I"
                name="history"
                block
                onChange={(e) => this.onChange("history", e)}
                placeholder=""
                type="text"
              />
              <ControlLabel>تخصص</ControlLabel>
              <FormControl
                className="w-100I"
                name="expertise"
                block
                onChange={(e) => this.onChange("expertise", e)}
                placeholder=""
                type="text"
              />

              <ControlLabel>تصویر</ControlLabel>
              <Uploader
                style={{ height: "20px", marginBottom: "30px" }}
                name="photoAlbum"
                placeholder="عکس"
                className="custom-file-input"
                type="file"
                multiple
                onChange={(e) => this.handleChangeAlbum(e)}
                accept="image/*"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleSubmit} appearance="primary">
              تایید
            </Button>
            <Button onClick={this.props.close} appearance="subtle">
              لغو
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
